import { FunctionalComponent, h } from "preact";
import { logEvent } from "../../logging/eventProducer";

interface Props {
  data: any;
  vidRef: any;
  setIsVideoLoaded: (value: boolean) => void;
}

const VideoSection: FunctionalComponent<Props> = (props: Props) => {
  const { data, vidRef, setIsVideoLoaded } = props;
  return (
    <div style={styles.imageHolder}>
      {/*Prefetch thumbnail*/}
      <img src={data.image_url} style={{ display: "none" }} />
      <video
        id="video"
        ref={vidRef}
        autoPlay={true}
        muted={false}
        loop={true}
        playsInline={true}
        onLoadedData={() => {
          logEvent("TEMPO_VIDEO_LOADED");
          setIsVideoLoaded(true);
          props.vidRef.current.play();
        }}
        style={styles.videoFrame}
      >
        <source type="video/mp4" src={data.video_url} />
      </video>
    </div>
  );
};

const styles = {
  videoFrame: {
    zIndex: 2,
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  imageHolder: { width: "100%", height: "100%" },
};

export default VideoSection;
