import { FunctionalComponent, h, createRef } from "preact";
// import data2 from './sample'
import "../../index.css"; // TODO: test if this import is redundant
import Campaign from "./Campaign";
import { useMediaQuery } from "react-responsive";

interface Props {
  cid: number;
  os: string;
}

// @ts-ignore
const CampaignMobileWrapper: FunctionalComponent = (props: Props) => {
  const { cid, os } = props;
  const isSmallMobile = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
      }}
    >
      <div
        style={
          isSmallMobile
            ? { height: "100%" }
            : {
                "max-width": "390px",
                "max-height": "844px",
                "min-width": "390px",
                "min-height": "844px",
                width: "390px",
                height: "100%",
                display: "flex",
                margin: "0 auto",
                position: "relative",
              }
        }
        id="main-container"
      >
        <Campaign cid={cid} os={os} isInterstitial={false} />
      </div>
    </div>
  );
};

export default CampaignMobileWrapper;
