/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FunctionalComponent, h } from "preact";
import { logEvent } from "../../logging/eventProducer";
import ThumbnailsIsland from "@tempoplatform/tpds/_dist/components/thumbnails-island";

interface Props {
  setSelectedProductIndex: (value: any) => void;
  isMuted: any;
  mute: () => void;
  setActiveComponent: (value: any) => void;
  setCurrentPage: (value: string) => void;
  data: any;
}

const BottomSection: FunctionalComponent<Props> = (props: Props) => {
  const { setSelectedProductIndex, isMuted, mute, setActiveComponent, data, setCurrentPage } =
    props;

  const imagesData = data.campaign_products.map((cp, index) => {
    return {
      url: cp.product.image_urls[0],
      callBack: () => {
        setCurrentPage("product");
        logEvent(`CLICKED_PRODUCT_${index}`);
        setSelectedProductIndex(index);
        if (!isMuted) {
          mute();
        }
        setSelectedProductIndex(index);
        setActiveComponent(index);
      },
    };
  });

  return (
    <ThumbnailsIsland
      backgroundColor={data.ad_style?.bottom_island?.background_color ?? "#96969621"}
      thumbnailsBackgroundColor={
        data.ad_style?.bottom_island?.thumbnails_background_color ?? "#ffffff21"
      }
      thumbnailsBorderRadius={data.ad_style?.bottom_island?.thumbnails_border_radius ?? 6}
      thumbnailsPadding={data.ad_style?.bottom_island?.thumbnails_padding ?? 0}
      removeEnclosingIsland={data.ad_style?.bottom_island?.remove_enclosing_island}
      items={imagesData}
    />
  );
};

export default BottomSection;
