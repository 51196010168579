import { FunctionalComponent, h } from "preact";
import { PLarge } from "@tempoplatform/tpds/_dist/elements/typography";
import { Down } from "../../../assets/svgs/ChevronSmall";

// TODO: verify this remaining code
// const Select = styled.select(() => [
//   css`
//     outline: 1px solid red;
//     -webkit-appearance: none;
//     ::-ms-expand {
//       display: none;
//     }
//   `,
// ]);

interface Props {
  optionsArray: Array<any>;
  selectedOption: any;
  handleChange: any;
}

const CustomSelect: FunctionalComponent<Props> = (props: Props) => {
  const { optionsArray, selectedOption, handleChange } = props;

  return (
    <div>
      <div className="relative w-full">
        <select onChange={handleChange} className="absolute w-full h-full opacity-0">
          {optionsArray.map((option: number) => {
            return (
              <option key={option} value={option} selected={option === selectedOption}>
                {option}
              </option>
            );
          })}
        </select>
        <div className="flex bg-white w-full h-[36px] rounded border-2 border-grey-dark-scale-500">
          <div className="flex w-[calc(100%-36px)] h-full items-center px-2">
            <PLarge isMedium className="leading-none relative top-[1px]">
              {selectedOption}
            </PLarge>
          </div>
          <div className="flex justify-center items-center w-[36px] h-full border-l-2 border-grey-dark-scale-500">
            <Down />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomSelect;
