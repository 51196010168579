import { FunctionalComponent, h } from "preact";
import { ProductVariantOptionsData } from "../../../types/types";
import { PSmall } from "@tempoplatform/tpds/_dist/elements/typography";
import clsx from "clsx";

interface Props {
  name: string;
  options: Array<ProductVariantOptionsData>;
  selectedIndex: number;
  setSelectedIndex: any;
  setProductVariant: (key: any, value: any) => void;
}

const ColorSelector: FunctionalComponent<Props> = (props: Props) => {
  return (
    <div>
      <PSmall isMedium className="leading-none !mb-1">
        Color
      </PSmall>
      <div className="flex gap-1.5 flex-wrap">
        {props.options.map(option => (
          <div
            className="relative w-[36px] h-[36px]"
            key={option.id}
            onClick={() => {
              props.setSelectedIndex(option.id);
              props.setProductVariant(props.name, option.name);
            }}
          >
            <img
              style={{ zIndex: "1" }}
              className="absolute w-[36px] h-[36px] rounded-md overflow-hidden opacity-75"
              src={"/assets/pngs/color-swatch-noise.png"}
            />
            <div
              className={clsx(`absolute rounded-md border-2 w-[36px] h-[36px]`)}
              style={{
                backgroundColor: `#${option.hex_color}`,
                borderColor: option.id === props.selectedIndex ? "#000000" : "transparent",
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColorSelector;
