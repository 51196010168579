import { FunctionalComponent, h } from "preact";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useState, useEffect, useRef } from "preact/hooks";
import { forwardRef } from "preact/compat";
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch";

interface Props {
  swiperRef: any;
  pid: any;
  product: any;
  initialImageIndex: number;
  currentPage: string;
  imageUrlIndexStarts: Array<number>;
}

let refs = [];
const ProductImageSlider: FunctionalComponent<Props> = forwardRef((props: Props, ref: any): any => {
  const { swiperRef, pid, product, initialImageIndex, imageUrlIndexStarts, currentPage } = props;
  const [imageUrls, setImageUrls] = useState<any>([]);
  const [my_swiper, set_my_swiper] = useState<any>({});

  // React doesn't allow creating refs dinamically, so we have to create an array of refs
  // and then use the index to access the ref
  // the number of refs created here should accomodate for the maximum number of images in a product
  refs = [];
  refs.push(
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
    useRef<ReactZoomPanPinchRef | null>(null),
  );

  useEffect(() => {
    updateStartImage(initialImageIndex);
    if (currentPage === "home") {
      resetTransforms();
    }
    function updateStartImage(value: any) {
      setImageUrls(
        product.image_urls.slice(
          value,
          imageUrlIndexStarts[imageUrlIndexStarts.indexOf(value) + 1],
        ),
      );
    }
  }, [swiperRef, pid, product, initialImageIndex, imageUrlIndexStarts, currentPage]);

  const resetTransforms = () => {
    refs.map((ref: any) => {
      if (ref.current) {
        ref.current.resetTransform();
      }
    });
  };

  const handleZoomChange = (ev: any) => {
    // allow panning only if the image is zoomed in
    if (ev.state.scale <= 1) {
      ev.instance.setup.panning.disabled = true;
      ev.instance.setup.doubleClick.mode = "zoomIn";
    } else {
      ev.instance.setup.doubleClick.mode = "reset";
      ev.instance.setup.panning.disabled = false;
    }
  };

  return (
    <Swiper
      slidesPerView={1}
      loop={false}
      pagination={{
        el: ".pagination-wrapper",
        clickable: true,
      }}
      modules={[Pagination]}
      className={`mySwiper${pid}`}
      onSlideChange={resetTransforms}
      onSwiper={swiper => {
        swiperRef.current = swiper;
      }}
      style={{ borderBottom: "1px solid #f1f1f1" }}
      onInit={ev => {
        set_my_swiper(ev);
      }}
    >
      {imageUrls.length > 0 &&
        imageUrls.map((image_url: string, i: number) => (
          <SwiperSlide key={i}>
            <TransformWrapper
              panning={{ disabled: true }}
              wheel={{ wheelDisabled: true }}
              limitToBounds={true}
              centerZoomedOut={true}
              doubleClick={{ mode: "zoomIn" }}
              onZoom={ev => handleZoomChange(ev)}
              onZoomStop={ev => handleZoomChange(ev)}
              onTransformed={ev => handleZoomChange(ev)}
              ref={refs[i]}
              maxScale={4}
            >
              <TransformComponent
                wrapperStyle={{
                  margin: "auto",
                }}
              >
                <div style={styles.zoomableSlide} className="swiper-zoom-container">
                  <img src={image_url} alt={`Image ${i}`} style={styles.imageTrueStyle} />
                </div>
              </TransformComponent>
            </TransformWrapper>
          </SwiperSlide>
        ))}
    </Swiper>
  );
});

const styles = {
  sliderDiv: {
    display: "flex",
    backgroundColor: "red",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  imageTrueStyle: {
    zIndex: 0,
    objectFit: "contain",
    maxWidth: "100%",
    maxHeight: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  zoomableSlide: {
    zIndex: 0,
    aspectRatio: "1",
    width: "100vw",
  },
};

export default ProductImageSlider;
