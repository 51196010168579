import { FunctionalComponent, h } from "preact";
import ExpandableSection from "@tempoplatform/tpds/_dist/components/expandable-section";
import ReviewsShort from "./reviews/ReviewsShort";

interface Props {
  product: any;
  productFlag: boolean;
  setReviewWrapper: (c: boolean) => void;
}

type InfoBoxItem = {
  title: string;
  description: string;
};

const ProductInformation: FunctionalComponent<Props> = (props: Props) => {
  const { product, setReviewWrapper, productFlag } = props;

  let info_boxes: InfoBoxItem[] = [];

  if (!Array.isArray(product.info_boxes)) {
    for (const info_title in product.info_boxes) {
      info_boxes.push({
        title: info_title,
        description: product.info_boxes[info_title],
      });
    }
  } else {
    info_boxes = JSON.parse(JSON.stringify(product.info_boxes));
  }

  const reviewScores = product.product_review_scores;
  const productReviews = product.product_reviews;

  return (
    <div
      style={{
        ...styles.productInfoWrapper,
        height: "auto",
      }}
    >
      {info_boxes.map((box: any, i: number) => (
        <div key={i}>
          <ExpandableSection
            shouldClose={!productFlag}
            title={box.title}
            children={box.description}
            excludeElementId="payment-button-wrapper"
            removeBorderTop={i !== 0}
          />
        </div>
      ))}
      {reviewScores && (
        <ReviewsShort
          setReviewWrapper={setReviewWrapper}
          reviews={productReviews}
          reviewScores={reviewScores}
        />
      )}
    </div>
  );
};

const styles = {
  productInfoWrapper: {
    boxSizing: "border-box",
    flex: 1,
    width: "100%",
    height: "auto",
  },
};

export default ProductInformation;
