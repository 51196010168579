/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createRef, FunctionalComponent, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import Alert from "@mui/material/Alert/Alert";
import { ActiveComponent } from "../../../website/components/state";
import ProductSelectSection from "./ProductSelectSection";
import UserReviews from "./reviews/UserReviews";
import { ProductData, SkuProductVariantOptionsData } from "../../types/types";
import ProductInformation from "./ProductInformation";
import ProductDetails from "./ProductDetails";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import ProductImageSlider from "./ProductImageSlider";
import ImagesAndFormAdType from "./ImagesAndFormAdType";
import { toCreatePaymentIntent, toUpdatePaymentIntent } from "../../api/api";
import { STRIPE_PUBLISHABLE_KEY } from "../../constants/keys";
import styled from "@emotion/styled";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import PaymentButton from "../checkout/PaymentButton";
import { logEvent } from "../../logging/eventProducer";
import TopHeader from "./subcomponents/TopHeader";
import ShippingModeSelect from "./subcomponents/ShippingModeSelect";
import BrandAwarenessCTA from "./subcomponents/BrandAwarenessCTA";
import { TEMPO_DOMAIN } from "../../constants/strings";

interface Props {
  pid: number;
  cid: number;
  selectedProductIndex: number;
  os: string;
  productFlag: boolean;
  data: any;
  swiperRef: any;
  currentPage: string;
  setCurrentPage: (value: string) => void;
  setActiveComponent: (c: ActiveComponent) => void;
  setSelectedProductIndex: (i: number) => void;
}

let paymentRequest: any;

const PaymentButtonWrapper = styled.div({
  width: "100%",
  position: "fixed",
  bottom: "0",
  left: "0",
  zIndex: "5",
  padding: "8px",
  background: "white",
  boxShadow: "2px 16px 29px 9px rgba(0, 0, 0, 0.5)",
});

const Product: FunctionalComponent<Props> = (props: Props) => {
  const {
    cid,
    pid,
    data,
    swiperRef,
    productFlag,
    currentPage,
    setCurrentPage,
    setSelectedProductIndex,
  } = props;
  const ad_type = data.ad_type;
  const product: ProductData = data.campaign_products[pid].product;
  const [quantity, setQuantity] = useState(1);
  const [paymentIntentId, setPaymentIntentId] = useState(undefined);
  const [shouldShowCheckout, setShouldShowCheckout] = useState(true);
  const [selectedShippingMode, setSelectedShippingMode] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const reviewWrapperRef = createRef();
  // const reviews = [reviews1, reviews2, reviews3, reviews4];
  const variants = product.product_variants ?? [];
  const [imageUrlIndexStarts, setImageUrlIndexStarts] = useState<Array<number>>([]);
  const [expanded, setExpanded] = useState(false);
  const [stripePayment, setStripePayment] = useState<boolean>(false);
  const [androidWebview, setAndroidWebview] = useState<boolean>(false);
  const [paying, setPaying] = useState<boolean>(false);
  const [paymentButtonLoading, setPaymentButtonLoading] = useState<boolean>(true);
  const [productVariant, setProductVariant] = useState<any>({});
  const [initialImageIndex, setInitialImageIndex] = useState(0);
  const [currentSelection, setCurrentSelection] = useState<Array<SkuProductVariantOptionsData>>([]);
  const [selectedSku, setSelectedSku] = useState<number>(0);
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState<string>("");
  const resetVariantSelection = () => {
    setSelectedShippingMode(null);
    swiperRef.current.slideTo(0);
    updateQuantity(1);
  };

  useEffect(() => {
    const allIndices = product.skus.map(obj => obj.image_url_index);
    const uniqueIndices = allIndices.filter((v, i) => allIndices.indexOf(v) == i);
    const sortedUniqueIndices = uniqueIndices.sort((a, b) => a - b);
    setImageUrlIndexStarts(sortedUniqueIndices);
    const pShippingOptions = product["campaign_product_shipping_options"];
    if (pShippingOptions && pShippingOptions.length > 0) {
      setSelectedShippingMode(pShippingOptions[0].shipping_option);
    }

    const initialState: Array<any> = [];
    for (const variant of product.product_variants) {
      initialState.push({
        product_variant_id: variant.id,
        product_variant_option_id: variant.product_variant_options[0].id,
      });
    }
    setCurrentSelection(initialState);
  }, [product]);

  useEffect(() => {
    setSku(currentSelection);
  }, [currentSelection]);

  const setSku = (selectedOptions: Array<SkuProductVariantOptionsData>) => {
    const foundIndex: any =
      product?.skus?.length > 0 &&
      product?.skus?.find((pr: any) => {
        // TODO: clean this up - it's only done like this for debugging purposes
        let matchSoFar = true;
        if (selectedOptions.length !== pr.sku_product_variant_options.length) {
          matchSoFar = false;
        }
        for (const [i, selectedOption] of selectedOptions.entries()) {
          if (
            selectedOption.product_variant_id !=
            pr.sku_product_variant_options[i].product_variant_id
          ) {
            matchSoFar = false;
          }
          if (
            selectedOption.product_variant_option_id !=
            pr.sku_product_variant_options[i].product_variant_option_id
          ) {
            matchSoFar = false;
          }
        }
        if (matchSoFar) {
          setSelectedSku(pr.id);
          return pr;
        }
        return false;
      });

    setInitialImageIndex(foundIndex?.image_url_index || 0);
  };

  const setReviewWrapper = (state: boolean): void => {
    if (state) {
      reviewWrapperRef.current.style.display = "flex";
      setTimeout(() => {
        reviewWrapperRef.current.classList.add("is_open");
      }, 100);
    } else {
      reviewWrapperRef.current.classList.remove("is_open");
      setTimeout(() => {
        reviewWrapperRef.current.style.display = "none";
      }, 400);
    }
  };

  const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const createPaymentIntent = async () => {
    const requestBody = {
      currency: "USD",
      price: Number(product.price),
      quantity: Number(quantity),
      product_name: product.name.toString(),
      campaign_id: cid,
      product_id: selectedSku,
    };
    try {
      const response = await toCreatePaymentIntent(requestBody);
      const data = await response.json();
      logEvent("PAYMENT_INTENT_SUCCESS");
      continueWithPaymentIntent(data);
      if (data.error) {
        return { error: data.error };
      }
      return data;
    } catch (err: any) {
      console.error(err);
      return { error: err.message };
    }
  };

  useEffect(() => {
    // @ts-ignore
    window.myEvent = new Event("myEvent");
    window.addEventListener(
      "myEvent",
      () => {
        setShouldShowCheckout(true);
      },
      false,
    );
    createPaymentIntent();
  }, [product]);

  const continueWithStripeCheckout = (response: any, stripe: any) => {
    // @ts-ignore
    const options = {
      clientSecret: response.client_secret,
      // Fully customizable with appearance API.
      appearance: {
        /*...*/
      },
    };
    const elements = stripe.elements(options);
    const paymentElement = elements.create("payment", {
      layout: {
        type: "accordion",
        defaultCollapsed: false,
        radios: true,
        spacedAccordionItems: false,
      },
    });
    paymentElement.mount("#payment-element");
    const form = document.getElementById("payment-form");
    // @ts-ignore
    form.addEventListener("submit", async event => {
      setPaying(true);
      event.preventDefault();
      const { error } = await stripe.confirmPayment({
        // @ts-ignore
        elements,
        confirmParams: {
          return_url: `${TEMPO_DOMAIN}/thank-you`,
        },
      });
      console.info("Error", error); // TODO: tidy this?
      if (error) {
        setPaying(false);
        const messageContainer = document.querySelector("#error-message");
        // @ts-ignore
        messageContainer.textContent = error.message;
      } else {
        setPaying(false);
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    });
  };

  const continueWithPaymentIntent = (response: any) => {
    setPaymentIntentId(response.id);
    setPaymentIntentClientSecret(response.client_secret);
    if (navigator.userAgent.includes("Tempo-Android-SDK")) {
      logEvent("NATIVE_ANDROID_CHECKOUT_BUTTON");
      setStripePayment(false);
      setAndroidWebview(true);
      setShouldShowCheckout(false);
    } else {
      try {
        // @ts-ignore
        const stripe = window.Stripe(STRIPE_PUBLISHABLE_KEY, {
          apiVersion: "2020-08-27",
        });
        const shippingPrice = selectedShippingMode ? selectedShippingMode.amount : 0;
        paymentRequest = stripe.paymentRequest({
          country: "US",
          currency: "usd",
          total: {
            label: product.name,
            amount: (product.price * quantity + shippingPrice) * 100,
          },
          requestPayerName: true,
          requestPayerEmail: true,
          requestShipping: true,
          displayItems: [
            {
              label: `${quantity} ${product.name}`,
              amount: product.price * 100,
            },
            ...(shippingPrice
              ? [
                  {
                    label: "Shipping",
                    amount: shippingPrice * 100,
                  },
                ]
              : []),
          ],
        });
        const elements = stripe.elements();
        // @ts-ignore
        const prButton = elements.create("paymentRequestButton", {
          paymentRequest,
          style: {
            paymentRequestButton: {
              height: "52px",
              borderRadius: "6px",
              width: "100%",
              alignSelf: "center",
              position: "relative",
              zIndex: 10,
            },
          },
        });
        prButton.on("click", function (event) {
          logEvent(`CLICKED_NATIVE_PAY_PRODUCT_${pid}`);
        });
        (async () => {
          try {
            // Check the availability of the Payment Request API first.
            const result = await paymentRequest.canMakePayment();
            if (navigator.userAgent.includes("Tempo-Android-SDK")) {
              logEvent("NATIVE_ANDROID_CHECKOUT_BUTTON");
              setStripePayment(false);
              setAndroidWebview(true);
              setShouldShowCheckout(false);
            } else if (result && result.link) {
              logEvent("STRIPE_CHECKOUT_BUTTON");
              setPaymentButtonLoading(false);
              setStripePayment(true);
              setAndroidWebview(false);
              continueWithStripeCheckout(response, stripe);
              setShouldShowCheckout(false);
            } else if ((result && result.applePay) || result.googlePay) {
              logEvent("NATIVE_CHECKOUT_BUTTON");
              setPaymentButtonLoading(false);
              setAndroidWebview(false);
              prButton.mount("#payment-request-button");
              setShouldShowCheckout(false);
              nativePayCheckout(stripe, response.id, response.client_secret);
            } else {
              setShouldShowCheckout(true);
              // @ts-ignore
              window.document.getElementById("payment-request-button").style.display = "none";
            }
          } catch (err) {
            console.error(err);
            console.error("Trying Android WebView GPay instead.");
            logEvent("NATIVE_ANDROID_CHECKOUT_BUTTON_AS_BACKUP");
            setStripePayment(false);
            setAndroidWebview(true);
            setShouldShowCheckout(false);
          }
        })();
      } catch (err) {
        console.error(err);
        console.error("Trying Android WebView GPay instead.");
        logEvent("NATIVE_ANDROID_CHECKOUT_BUTTON_AS_BACKUP");
        setStripePayment(false);
        setAndroidWebview(true);
        setShouldShowCheckout(false);
      }
    }
  };

  const nativePayCheckout = (stripe: any, intentId: string, clientSecret: string) => {
    paymentRequest.on("shippingaddresschange", async (ev: any) => {
      if (ev.shippingAddress.country !== "US") {
        ev.updateWith({ status: "invalid_shipping_address" });
      } else {
        //   // Perform server-side request to fetch shipping options
        //   const response = await fetch('/calculateShipping', {
        //     body: JSON.stringify({
        //       shippingAddress: ev.shippingAddress
        //     })
        //   });
        //   const result = await response.json();
        const shippingOption = selectedShippingMode
          ? {
              id: `${selectedShippingMode.id}`,
              label: selectedShippingMode.display_name,
              detail: `Delivery in ${selectedShippingMode.del_est_min_value} to ${selectedShippingMode.del_est_max_value} business days`,
              amount: selectedShippingMode.amount,
            }
          : {
              id: "free",
              label: "Free",
              detail: `Delivery in 5 to 7 business days`,
              amount: 0,
            };
        ev.updateWith({
          status: "success",
          shippingOptions: [shippingOption],
        });
      }
    });
    paymentRequest.on("paymentmethod", async (ev: any) => {
      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false },
      );
      const shippingAddress = ev.shippingAddress;
      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete("fail");
      } else {
        const shippingUpdateResponse = await updatePaymentIntentShipping(
          paymentIntent.id,
          shippingAddress,
        );
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete("success");
        if (paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe.confirmCardPayment(clientSecret);
          setOpen(true);
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
          } else {
            // The payment has succeeded.
          }
        } else {
          // The payment has succeeded.
          window.location.href = `${TEMPO_DOMAIN}/thank-you`;
        }
      }
    });
  };

  const updateQuantity = async (quantity: number) => {
    setQuantity(quantity);
    if (paymentIntentId && paymentRequest) {
      const response = await updatePaymentIntentQuantity(paymentIntentId, quantity);
      if (response.error) {
        console.error(response.error);
        return;
      }
      paymentRequest.update({
        total: {
          label: product.name,
          amount: product.price * quantity * 100,
        },
      });
    }
  };

  // @ts-ignore
  const updatePaymentIntentQuantity = async (paymentIntentId, quantity) => {
    const requestBody = {
      price: product.price,
      quantity,
    };
    try {
      const response = await toUpdatePaymentIntent(requestBody, paymentIntentId);
      const data = await response.json();
      if (data.error) {
        return { error: data.error };
      }
      return data;
    } catch (err) {
      // @ts-ignore
      return { error: err.message };
    }
  };

  // @ts-ignore
  const updatePaymentIntentShipping = async (paymentIntentId: string, shippingDetails: any) => {
    const requestBody = {
      shipping: {
        address: {
          city: shippingDetails.city,
          country: shippingDetails.country,
          line1: shippingDetails.addressLine[0],
          line2: shippingDetails.addressLine[1],
          postal_code: shippingDetails.postalCode,
          state: shippingDetails.region,
        },
        name: shippingDetails.recipient,
        phone: shippingDetails.phone,
      },
    };
    try {
      const response = await toUpdatePaymentIntent(requestBody, paymentIntentId);
      const data = await response.json();
      if (data.error) {
        return { error: data.error };
      }
      return data;
    } catch (err) {
      // @ts-ignore
      return { error: err.message };
    }
  };
  const resetSelection = () => {
    setSelectedShippingMode(null);
  };

  const productReviews = product.product_reviews;
  const reviewScores = product.product_review_scores;
  return (
    <div
      style={{
        ...styles.productPageWrapper,
        paddingBottom: ad_type === "shoppable" ? "100px" : "40px",
      }}
    >
      {productReviews && reviewScores && (
        <div className="user-review-wrapper" ref={reviewWrapperRef}>
          <UserReviews
            reviews={productReviews}
            reviewScores={reviewScores}
            setReviewWrapper={setReviewWrapper}
          />
        </div>
      )}
      <TopHeader
        pid={pid}
        product={product}
        data={data}
        resetVariantSelection={resetVariantSelection}
        setActiveComponent={props.setActiveComponent}
        setCurrentPage={setCurrentPage}
        resetSelection={resetSelection}
        setReset={setReset}
      />
      <ProductImageSlider
        swiperRef={swiperRef}
        pid={pid}
        product={product}
        currentPage={currentPage}
        initialImageIndex={initialImageIndex}
        imageUrlIndexStarts={imageUrlIndexStarts}
        //ref={childRef}
      />
      <div style={styles.pagination} className="pagination-wrapper" />
      {ad_type !== "images_and_form" && (
        <ProductDetails
          ad_type={ad_type}
          reviewScores={reviewScores}
          product={product}
          data={data}
          quantity={quantity}
        />
      )}
      {ad_type !== "images_and_form" && variants.length ? (
        <div style={{ padding: "0 16px 16px 16px" }}>
          <ProductSelectSection
            variants={variants}
            reset={reset}
            data={data}
            ad_type={ad_type}
            product={product}
            setReset={setReset}
            setProductVariant={(key: any, value: any) => {
              setProductVariant({ ...productVariant, [key]: value });
            }}
            productVariant={productVariant}
            currentSelection={currentSelection}
            setCurrentSelection={setCurrentSelection}
            resetVariantSelection={resetVariantSelection}
            selectedQuantity={quantity}
            handleQuantitySelectChange={(e: any) => updateQuantity(e.target.value)}
          />
        </div>
      ) : null}
      {ad_type === "brand_awareness" && data.cta && data.cta !== "" && (
        <BrandAwarenessCTA data={data} product={product} />
      )}
      {ad_type === "shoppable" && (
        <>
          <PaymentButtonWrapper id="payment-button-wrapper">
            {androidWebview ? (
              <PaymentButton clientSecret={paymentIntentClientSecret} product={product} />
            ) : stripePayment ? (
              <button id="submit" style={styles.checkoutButton} onClick={() => setExpanded(true)}>
                Checkout
              </button>
            ) : (
              <div id="payment-request-button" style={styles.nativeButtonWrapper} />
            )}
          </PaymentButtonWrapper>
          <ShippingModeSelect
            selectedShippingMode={selectedShippingMode}
            shippingModes={product["campaign_product_shipping_options"]}
            setSelectedShippingMode={setSelectedShippingMode}
          />
        </>
      )}
      {ad_type !== "images_and_form" && (
        <ProductInformation
          // reset={reset}
          // setReset={setReset}
          setReviewWrapper={setReviewWrapper}
          productFlag={productFlag}
          product={product}
          // isAnyVariant={Boolean(variants.length)}
        />
      )}
      {ad_type === "images_and_form" && (
        <ImagesAndFormAdType
          productFlag={productFlag}
          data={data}
          product={product}
          setSelectedProductIndex={setSelectedProductIndex}
          resetVariantSelection={resetVariantSelection}
          cid={cid}
        />
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity="warning" sx={{ width: "100%" }}>
          Sorry, your payment was not successful. Please try again with a different payment method.
        </Alert>
      </Snackbar>
      <Drawer
        anchor={"bottom"}
        open={expanded}
        onClose={() => setExpanded(false)}
        variant="persistent"
        elevation={20}
        PaperProps={{
          sx: {
            height: "420px",
            minHeight: "420px",
            backgroundColor: "#CCCCCC",
          },
        }}
      >
        <div style={styles.closeIconWrapper}>
          <IconButton
            color="default"
            aria-label="back"
            size={"large"}
            onClick={() => {
              setExpanded(false);
            }}
            style={{
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingBottom: "10px",
          }}
        >
          <form id="payment-form">
            <div
              id="error-message"
              style={{
                textAlign: "centre",
                color: "red",
                width: "100%",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            />
            <div id="payment-element" />
            {paying ? (
              <button id="submit" style={{ ...styles.submitButton, opacity: 0.7 }} disabled>
                <CircularProgress color="inherit" size={20} />
                <p style={styles.payingText}>Paying..</p>
              </button>
            ) : (
              <button id="submit" style={styles.submitButton}>
                <p style={styles.submitText}>Submit</p>
              </button>
            )}
          </form>
        </div>
      </Drawer>
    </div>
  );
};

const styles = {
  pagination: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  child: {
    height: "5%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "red",
  },
  productPageWrapper: {
    flexDirection: "row",
    flex: 1,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: 20,
    overflowY: "scroll",
  },
  gridWrapper: {
    height: "44px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    alignSelf: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  checkoutButton: {
    width: "100%",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(98 90 250)",
    border: "0px solid black",
    borderRadius: "5px",
    fontSize: "20px",
    color: "#FFFFFF",
    fontWeight: "400",
  },
  nativeButtonWrapper: {
    flex: 1,
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "8px",
  },
  closeIconWrapper: {
    float: "right",
    margin: "auto",
    marginRight: "0em",
    textAlign: "right",
    width: "20%",
  },
  submitButton: {
    width: "100%",
    height: "50px",
    display: "flex",
    marginTop: "10px",
    marginBottom: "20px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(98 90 250)",
    border: "0px solid black",
    borderRadius: "5px",
    fontSize: "20px",
    color: "#FFFFFF",
    fontWeight: "700",
  },
  cardFormWrapper: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  submitText: { fontSize: "20px", color: "#FFFFFF", fontWeight: "700" },
  payingText: { fontSize: "14px", color: "#FFFFFF", marginLeft: "10px" },
};

export default Product;
