import { FunctionalComponent, h } from "preact";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { H3, H6 } from "@tempoplatform/tpds/_dist/elements/typography";
import { Rating } from "@mui/material";
import ReviewBox from "./ReviewBox";
import dayjs from "dayjs";
import { simplifyLongNumber } from "../../../utils/number";

interface UserReview {
  content: string;
  user_name: string;
  star_rating: any;
  description: string;
  date: string;
  username: string;
  stars: number;
}

// interface Reviews {
// 	total_stars: number;
// 	count: number;
// 	reviews: UserReview[];
// }

interface Props {
  reviews: any;
  reviewScores: any;
  setReviewWrapper: (state: boolean) => void;
}

const UserReviews: FunctionalComponent<Props> = (props: Props) => {
  const { reviews, reviewScores } = props;
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        background: "white",
        display: "flex",
        flexFlow: "column",
      }}
    >
      <div className="px-4 py-2 border-b border-window flex items-center">
        <ArrowBackIcon
          onClick={() => {
            props.setReviewWrapper(false);
          }}
        />
        <H6 isMedium className="ml-2">
          Back
        </H6>
      </div>
      <div
        style={{
          position: "relative",
          borderRadius: "50%",
          zIndex: 2,
          height: "auto",
          width: "100%",
          display: "flex",
          paddingTop: "20px",
          paddingBottom: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            fontSize: "1.2em",
            fontWeight: "bold",
            color: "black",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <H3 isMedium>{reviewScores.score}/5</H3>
          <div
            style={{
              height: "40%",
            }}
          >
            <Rating defaultValue={reviewScores.score} precision={0.1} readOnly />
          </div>
          <div
            style={{
              color: "#565959",
              fontSize: 14,
              fontWeight: 500,
              height: "20%",
            }}
          >
            {simplifyLongNumber(reviewScores.count)} Reviews
          </div>
        </span>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
          top: "0px",
          right: "0px",
          left: "0px",
        }}
      >
        <div
          style={{
            padding: "1%",
            height: "100%",
            width: "90%",
          }}
        >
          {reviews.map((review: UserReview, index: number) => {
            return (
              <ReviewBox
                key={index}
                stars={review.star_rating}
                username={review.user_name}
                date={dayjs(review.date).format("YYYY/MM/DD")}
                description={review.content}
              />
            );
          })}
          <div style={{ height: "1em" }}>
            <span />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserReviews;
