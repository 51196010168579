import { h } from "preact";

export function Up() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
      <path fill="#D9D9D9" d="M0 0H12V12H0z" opacity="0.01" />
      <path
        fill="#000"
        d="M11.23 8.701a.906.906 0 00-.023-1.312L6.895 3.264a.92.92 0 00-1.29 0L1.294 7.389A.906.906 0 001.27 8.7a.906.906 0 001.312.024l3.68-3.493 3.656 3.493a.9.9 0 00.657.257.887.887 0 00.656-.28z"
      />
    </svg>
  );
}

export function Right() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
      <path fill="#D9D9D9" d="M0 0H12V12H0z" opacity="0.01" />
      <path
        fill="#000"
        d="M8.73 5.606L4.607 1.293a.906.906 0 00-1.313-.023.906.906 0 00-.023 1.312L6.762 6.24 3.27 9.919a.906.906 0 00.023 1.312.885.885 0 00.633.258c.258 0 .516-.094.68-.282L8.73 6.895a.92.92 0 000-1.29z"
      />
    </svg>
  );
}

export function Down() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
      <path fill="#D9D9D9" d="M0 0H12V12H0z" opacity="0.01" />
      <path
        fill="#000"
        d="M11.207 4.606a.906.906 0 00.024-1.313.906.906 0 00-1.313-.023L6.262 6.762 2.582 3.27a.906.906 0 00-1.312.023.906.906 0 00.023 1.313L5.606 8.73a.9.9 0 00.656.258c.21 0 .445-.07.633-.258l4.312-4.125z"
      />
    </svg>
  );
}

export function Left() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
      <path fill="#D9D9D9" d="M0 0H12V12H0z" opacity="0.01" />
      <path
        fill="#000"
        d="M8.701 1.27a.906.906 0 00-1.312.023L3.264 5.606a.92.92 0 000 1.289l4.125 4.312c.164.188.422.282.68.282.21 0 .445-.07.632-.258a.906.906 0 00.024-1.313L5.232 6.262l3.493-3.68A.906.906 0 008.7 1.27z"
      />
    </svg>
  );
}
