import { FunctionalComponent, h } from "preact";
import { P } from "@tempoplatform/tpds/_dist/elements/typography";
import { Rating } from "@mui/material";

interface Props {
  stars: any;
  username: string;
  date: string;
  description: string;
}

const ReviewBox: FunctionalComponent<Props> = props => {
  const { stars, username, date, description } = props;
  return (
    <div className="bg-grey-light-scale-200 rounded px-4 pt-2.5 pb-1.5 mb-2.5">
      <div className="border-b border-grey-light-scale-400 flex justify-between w-auto pb-2.5">
        <Rating defaultValue={stars} precision={0.1} size="small" readOnly />
        <div className="flex gap-x-2 w-auto">
          <P
            isMedium
            style={{
              maxWidth: "120px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {username}
          </P>
          <P>{date}</P>
        </div>
      </div>
      <div className="py-2.5">
        <P>{description}</P>
      </div>
    </div>
  );
};

export default ReviewBox;
