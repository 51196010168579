export const getCapitalizeWord = (word: any) => {
  const temp = word.split(" ");
  let wordToReturn = "";
  temp?.length > 0 &&
    temp?.map((t: any) => {
      const word = t?.split("")[0]?.toUpperCase() + t?.split("")?.slice(1).join("").toLowerCase();
      wordToReturn = `${wordToReturn} ${word}`;
    });
  return wordToReturn;
};
