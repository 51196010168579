import { FunctionalComponent, h } from "preact";
import { P, PSmall } from "@tempoplatform/tpds/_dist/elements/typography";

interface Props {
  data: any;
}

const BrandInfo: FunctionalComponent<Props> = (props: Props) => {
  const { data } = props;
  return (
    <div>
      <P
        isMedium
        className="leading-none pb-1"
        style={{ color: data?.ad_style?.top_island?.text_color ?? "white" }}
      >
        {data.brand_name}
      </P>
      <PSmall
        className="leading-none"
        style={{ color: data.ad_style?.top_island?.text_color ?? "white" }}
      >
        {data.headline}
      </PSmall>
    </div>
  );
};

export default BrandInfo;
