import { FunctionalComponent, h } from "preact";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "preact/hooks";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface InfoBox {
  title: string;
  description: string;
}

interface Props {
  infoBox: InfoBox;
  reset: boolean;
  setReset: any;
}

const InfoBox: FunctionalComponent<Props> = (props: Props) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = (expanded: boolean) => {
    props.setReset(true);
    setExpanded(expanded);
    props.setReset(false);
  };

  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  let showReadMoreButton;

  /* Crops text at specific number of characters */
  const cutoffLimit = 300;
  const cropDescription = (description: string) => {
    if (description.length > cutoffLimit) {
      let croppedText = description.substring(0, cutoffLimit);

      /* makes sure it ends on a full word */
      if (description.charAt(cutoffLimit) !== " ") {
        let lastSpaceIndex = croppedText.lastIndexOf(" ");
        if (lastSpaceIndex === -1) {
          lastSpaceIndex = cutoffLimit;
        }
        croppedText = croppedText.substring(0, lastSpaceIndex);
        /* makes sure it ends an alphanumeric character */
        if (!/\w$/.test(croppedText)) {
          lastSpaceIndex = croppedText.length - 1;
          croppedText = croppedText.substring(0, lastSpaceIndex);
        }
      }
      showReadMoreButton = true;
      return `${croppedText}... `;
    }
    showReadMoreButton = false;
    return description;
  };

  const truncatedDescription = cropDescription(props.infoBox.description);

  useEffect(() => {
    if (props.reset) {
      setExpanded(false);
      props.setReset(false);
    }
  }, [props.reset]);

  return (
    <div>
      {/* -- INFOBOX LIST BUTTON -- */}
      <div
        onClick={() => handleExpandClick(true)}
        style={{
          fontSize: "1em",
          fontWeight: 500,
          padding: "0.8em 0",
          color: "black",
          // textTransform: 'uppercase'
        }}
      >
        {props.infoBox.title}
        <ExpandMoreIcon
          style={{
            color: "black",
            float: "right",
            margin: "auto",
            transform: "rotate(270deg)",
          }}
        />
      </div>
      {expanded && <div style={styles.tapWrapper} onClick={() => setExpanded(false)} />}
      <Drawer
        anchor={"bottom"}
        open={expanded}
        onClose={() => handleExpandClick(false)}
        PaperProps={{
          sx: {
            // alignSelf: 'center',
            position: "absolute",
            height: "revert",
            minHeight: "40%",
            maxHeight: "40%",
            zIndex: 50,
          },
        }}
        BackdropProps={{
          style: { position: "absolute", height: "revert", width: "revert" },
        }}
        ModalProps={{
          container: document.getElementById("main-container"),
          style: { position: "absolute", height: "revert", width: "revert" },
        }}
        variant="persistent"
      >
        {/* -- THE INFOBOX HEADER BAR -- */}
        <div
          style={{
            backgroundColor: "black",
            height: "3em",
            fontSize: "1em",
            fontWeight: 500,
            color: "white",
            // textTransform: 'uppercase',
            display: "flex",
            alignItems: "center",
            position: "relative",
            zIndex: 500,
          }}
        >
          <span style={{ marginLeft: "1em" }}>{props.infoBox.title}</span>

          {/* -- THE RIGHT AREA OF HEADER BAR -- */}
          <div
            style={{
              float: "right",
              margin: "auto",
              marginRight: "0em",
              textAlign: "right",
              width: "20%",
            }}
          >
            {/* -- THE 'X' CLOSE BUTTON */}
            <IconButton
              color="default"
              aria-label="back"
              size={"large"}
              onClick={() => {
                handleExpandClick(false);
                toggleDescription(); // Collapses any expanded text
              }}
              style={{
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        {/* -- THE INFOBOX TEXT AREA -- */}
        <div
          style={{
            padding: "1em 1em 0.5em",
            height: "45%",
            overflowY: "scroll",
          }}
        >
          {/* -- THE INFOBOX TEXT ITSELF -- */}
          <span
            className="info-box"
            style={{
              whiteSpace: "pre-line",
              overflowY: "scroll",
            }}
          >
            {showFullDescription ? (
              <span dangerouslySetInnerHTML={{ __html: props.infoBox.description }} />
            ) : (
              <span>
                <span dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
                {showReadMoreButton && (
                  <span
                    onClick={toggleDescription}
                    style={{
                      fontStyle: "italic",
                      fontWeight: "bold",
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                      fontSize: "0.9em",
                      color: "#8A8A8A",
                    }}
                  >
                    <br />
                    <br />
                    READ MORE
                  </span>
                )}
              </span>
            )}
          </span>
        </div>
      </Drawer>
    </div>
  );
};

const styles = {
  tapWrapper: {
    width: "100%",
    height: "60%",
    position: "absolute",
    top: 0,
    zIndex: 30,
  },
};

export default InfoBox;
