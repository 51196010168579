const products = {
  data: {
    campaign: [
      {
        logo_url: "https://daxbiqjdiea11.cloudfront.net/images/20.png",
        image_url: "https://daxbiqjdiea11.cloudfront.net/images/19.png",
        video_url:
          "https://tempo-campaign-assets.s3.us-east-1.amazonaws.com/videos/maybelline-1.mp4",
        top_hex_color: "#FFFFFF",
        bottom_hex_color: "#DDDDDD",
        headline: "Cosmetics for all!",
        brand_name: "Maybelline",
        campaign_products: [
          {
            product: {
              image_urls: [
                "https://daxbiqjdiea11.cloudfront.net/images/1.png",
                "https://daxbiqjdiea11.cloudfront.net/images/2.png",
                "https://daxbiqjdiea11.cloudfront.net/images/3.png",
                "https://daxbiqjdiea11.cloudfront.net/images/4.png",
              ],
              url: "https://www.maybelline.com/lip-makeup/lip-gloss/lifter-gloss-lip-gloss-makeup-with-hyaluronic-acid",
              name: "LIFTER GLOSS",
              info_boxes: [
                {
                  title: "Description",
                  description:
                    "Maybelline NY's Lifter Gloss Bronzed delivers sun-kissed glossy lips and a fuller look. No Filler. Now, in five bronzed shades inspired by golden hour, this lip gloss formula with hyaluronic acid leaves behind lasting hydration and fuller-looking lips.",
                },
                {
                  title: "How to Use",
                  description:
                    "Step 1. Apply lip gloss with the XL Wand™ applicator in the center of your upper lip and follow the contours of your mouth. Step 2. Glide the lip gloss across the entire bottom lip.",
                },
                {
                  title: "Benefits",
                  description:
                    "Maybelline New York's Lifter Gloss Bronzed delivers glossy lips and a full look. Now, in five bronzed shades inspired by golden hour. Take your look to the next level with sun-kissed pigments that create a warm, glowing shine. Its breakthrough formula with Hyaluronic Acid visibly smooths lip surface and enhances lip contour with high shine for hydrated, fuller-looking lips. No Filler. Plus, its XL Wand™ transforms lips in an easy, one swipe application. Available in Rust, Copper, Bronze, Gold and Sun.",
                },
                {
                  title: "Ingredients",
                  description:
                    "G2033552 C18-36 Acid Triglyceride, Bis-Diglyceryl Polyacyladipate-2, Pentaerythrityl Tetraisostearate, Polybutene, Tridecyl Trimellitate, Diisostearyl Malate, Silica Dimethyl Silylate, Phenoxyethanol, Ethylhexyl Palmitate, Pentaerythrityl Tetra-Di-T-Butyl Hydroxyhydrocinnamate, Ethylhexylglycerin, Calcium Sodium Borosilicate, Sorbic Acid, Calcium Aluminum Borosilicate, Tocopheryl Acetate, Sodium Saccharin, Alumina, Synthetic Fluorphlogopite, Silica, Polybutylene Terephthalate, Trihydroxystearin, Cocos Nucifera Oil / Coconut Oil, Aluminum Hydroxide, Ethylene/Va Copolymer, Acrylates Copolymer, Tin Oxide, Magnesium Silicate, Sodium Hyaluronate, Tocopherol, Glucomannan, Parfum / Fragrance [+/- May Contain / Peut Contenir Ci 77891 / Titanium Dioxide, Ci 77491, Ci 77492, Ci 77499 / Iron Oxides, Mica, Ci 15850 / Red 7, Ci 45410 / Red 28 Lake, Ci 15985 / Yellow 6 Lake, Ci 42090 / Blue 1 Lake, Ci 15850 / Red 6, Ci 17200 / Red 33 Lake, Ci 45380 / Red 22 Lake, Ci 19140 / Yellow 5 Lake] Fil T243599/6",
                },
              ],
              price: 9.49,
              product_variants: [
                {
                  id: 1,
                  name: "size",
                  product_variant_options: [
                    {
                      id: 1,
                      name: "small",
                      hex_color: null,
                    },
                    {
                      id: 2,
                      name: "large",
                      hex_color: null,
                    },
                  ],
                },
                {
                  id: 2,
                  name: "color",
                  product_variant_options: [
                    {
                      id: 3,
                      name: "petal",
                      hex_color: "dd6f7f",
                    },
                    {
                      id: 4,
                      name: "bronze",
                      hex_color: "d78c53",
                    },
                  ],
                },
              ],
              skus: [
                {
                  id: 3,
                  image_url_index: 2,
                  price: 19.99,
                  seller_sku_id: "ABCD-3",
                  sku_product_variant_options: [
                    {
                      product_variant_id: 1,
                      product_variant_option_id: 1,
                    },
                    {
                      product_variant_id: 2,
                      product_variant_option_id: 4,
                    },
                  ],
                },
                {
                  id: 1,
                  image_url_index: 0,
                  price: 19.99,
                  seller_sku_id: "TRY-123",
                  sku_product_variant_options: [
                    {
                      product_variant_id: 1,
                      product_variant_option_id: 1,
                    },
                    {
                      product_variant_id: 2,
                      product_variant_option_id: 3,
                    },
                  ],
                },
                {
                  id: 4,
                  image_url_index: 2,
                  price: 29.99,
                  seller_sku_id: "ABCD-2",
                  sku_product_variant_options: [
                    {
                      product_variant_id: 1,
                      product_variant_option_id: 2,
                    },
                    {
                      product_variant_id: 2,
                      product_variant_option_id: 4,
                    },
                  ],
                },
                {
                  id: 2,
                  image_url_index: 0,
                  price: 29.99,
                  seller_sku_id: "ABCD-4",
                  sku_product_variant_options: [
                    {
                      product_variant_id: 1,
                      product_variant_option_id: 2,
                    },
                    {
                      product_variant_id: 2,
                      product_variant_option_id: 3,
                    },
                  ],
                },
              ],
            },
          },
          {
            product: {
              image_urls: [
                "https://daxbiqjdiea11.cloudfront.net/images/5.png",
                "https://daxbiqjdiea11.cloudfront.net/images/6.png",
                "https://daxbiqjdiea11.cloudfront.net/images/7.png",
                "https://daxbiqjdiea11.cloudfront.net/images/8.png",
                "https://daxbiqjdiea11.cloudfront.net/images/9.png",
                "https://daxbiqjdiea11.cloudfront.net/images/10.png",
              ],
              url: "https://www.maybelline.com/face-makeup/concealer/instant-age-rewind-eraser-dark-circles-concealer-treatment",
              name: "INSTANT AGE REWIND®",
              info_boxes: [
                {
                  title: "Description",
                  description:
                    "Winner of Allure's Best of Beauty Award for 2018 and the Spring Beauty O-Wards, Maybelline's Instant Age Rewind® Eraser Dark Circles Treatment Concealer makes the eye area appear radiant and refreshed in no time.",
                },
                {
                  title: "How to Use",
                  description:
                    "Step 1. Twist collar of the Micro-Corrector Applicator in the direction of arrows until the concealer is visible on the sponge (it may take some turns on first use).\nStep 2. Using the applicator, apply concealer directly to the under-eye area, blending in an outward motion.\nStep 3. For extreme dark circles, apply the Neutralizer shade under concealer shade.\nStep 4. To add a luminous touch, apply the Brightener shade to the inner corner of eyes, cheek and brow bones and, bridge of the nose.",
                },
                {
                  title: "Benefits",
                  description:
                    "This concealer for dark circles and fine lines instantly creates a radiant, refreshed looking eye area. Infused with goji berry and Haloxyl™. Packaging may vary, what you receive may not be what is reflected on site.",
                },
                {
                  title: "Ingredients",
                  description:
                    "G852364 1 Aqua/Water/Eau, Cyclopentasiloxane, Dimethicone, Isododecane, Glycerin, Peg-9 Polydimethylsiloxyethyl Dimethicone, Butylene Glycol, Dimethicone Crosspolymer, Nylon-12, Disteardimonium Hectorite, Cyclohexasiloxane, Peg-10 Dimethicone, Cetyl Peg/Ppg-10/1 Dimethicone, Phenoxyethanol, Sodium Chloride, Polyglyceryl-4 Isostearate, Caprylyl Glycol, Disodium Stearoyl Glutamate, Ethylhexylglycerin, Methylparaben, Lycium Barbarum Fruit Extract, Chlorphenesin, Ethylparaben, Aluminum Hydroxide, Peg-9, N-Hydroxysuccinimide, Palmitoyl Oligopeptide, Chrysin, Palmitoyl Tetr Apeptide-7. [+/- May Contain/Peut Contenir: Ci 77891/Titanium Dioxide, Ci 77491, Ci 77492, Ci 77499/Iron Oxides] F.I.L. D44980/4 U.S.Patent Pending",
                },
              ],
              price: 9.99,
              product_variants: [
                {
                  id: 3,
                  name: "color",
                  product_variant_options: [
                    {
                      id: 5,
                      name: "Cool Ivory 095",
                      hex_color: "f3e6de",
                    },
                    {
                      id: 7,
                      name: "Brightener 160",
                      hex_color: "fcd1c3",
                    },
                    {
                      id: 6,
                      name: "Golden 142",
                      hex_color: "C99A6C",
                    },
                  ],
                },
              ],
              skus: [
                {
                  id: 5,
                  image_url_index: 0,
                  price: 9.99,
                  seller_sku_id: "ABCD-5",
                  sku_product_variant_options: [
                    {
                      product_variant_id: 3,
                      product_variant_option_id: 5,
                    },
                  ],
                },
                {
                  id: 6,
                  image_url_index: 2,
                  price: 9.99,
                  seller_sku_id: "ABCD-6",
                  sku_product_variant_options: [
                    {
                      product_variant_id: 3,
                      product_variant_option_id: 6,
                    },
                  ],
                },
                {
                  id: 7,
                  image_url_index: 4,
                  price: 9.99,
                  seller_sku_id: "ABCD-7",
                  sku_product_variant_options: [
                    {
                      product_variant_id: 3,
                      product_variant_option_id: 7,
                    },
                  ],
                },
              ],
            },
          },
          {
            product: {
              image_urls: [
                "https://daxbiqjdiea11.cloudfront.net/images/11.png",
                "https://daxbiqjdiea11.cloudfront.net/images/12.png",
                "https://daxbiqjdiea11.cloudfront.net/images/13.png",
                "https://daxbiqjdiea11.cloudfront.net/images/14.png",
              ],
              url: "https://www.maybelline.com/face-makeup/foundation-makeup/fit-me-matte-poreless-foundation/classic-beige",
              name: "FIT ME!®",
              info_boxes: [
                {
                  title: "Description",
                  description:
                    "Fit Me® Matte + Poreless Foundation face makeup. This lightweight foundation mattifies and refines pores and leaves a natural, seamless finish.",
                },
                {
                  title: "How to Use",
                  description:
                    "Apply foundation onto skin and blend with fingertips or an applicator.",
                },
                {
                  title: "Benefits",
                  description:
                    "Ideal for normal to oily skin, our exclusive matte foundation formula features micro-powders to control shine and blur pores. Pore minimizing foundation. All day wear. Non-comedogenic. Dermatologist tested. Allergy tested.",
                },
                {
                  title: "Ingredients",
                  description:
                    "CYCLOHEXASILOXANE NYLON-12 ISODODECANE ALCOHOL DENAT. CYCLOPENTASILOXANE PEG-10 DIMETHICONE CETYL PEG/PPG-10/1 DIMETHICONE PEG-20 POLYGLYCERYL-4 ISOSTEARATE DISTEARDIMONIUM HECTORITE PHENOXYETHANOL MAGNESIUM SULFATE DISODIUM STEAROYL GLUTAMATE HDI/TRIMETHYLOL HEXYLLACTONE CROSSPOLYMER TITANIUM DIOXIDE",
                },
              ],
              price: 8.49,
              product_variants: [
                {
                  id: 4,
                  name: "color",
                  product_variant_options: [
                    {
                      id: 10,
                      name: "Classic Beige 245",
                      hex_color: "d99a81",
                    },
                    {
                      id: 8,
                      name: "Fair Porcelain 102",
                      hex_color: "ffeae3",
                    },
                  ],
                },
              ],
              skus: [
                {
                  id: 8,
                  image_url_index: 0,
                  price: 8.49,
                  seller_sku_id: "ABCD-8",
                  sku_product_variant_options: [
                    {
                      product_variant_id: 4,
                      product_variant_option_id: 8,
                    },
                  ],
                },
                {
                  id: 9,
                  image_url_index: 2,
                  price: 8.49,
                  seller_sku_id: "ABCD-9",
                  sku_product_variant_options: [
                    {
                      product_variant_id: 4,
                      product_variant_option_id: 10,
                    },
                  ],
                },
              ],
            },
          },
          {
            product: {
              image_urls: [
                "https://daxbiqjdiea11.cloudfront.net/images/15.png",
                "https://daxbiqjdiea11.cloudfront.net/images/16.png",
                "https://daxbiqjdiea11.cloudfront.net/images/17.png",
                "https://daxbiqjdiea11.cloudfront.net/images/18.png",
              ],
              url: "https://www.maybelline.com/eye-makeup/eyebrow-makeup/express-brow-2-in-1-pencil-and-powder-eyebrow-makeup",
              name: "EXPRESS BROW",
              info_boxes: [
                {
                  title: "Description",
                  description:
                    "Try Express Brow 2-In-1 Eyebrow Pencil + Powder by Maybelline for full natural-looking eyebrows, teardrop tip to shape brows and a powder sponge to fill and smooth.",
                },
                {
                  title: "How to Use",
                  description:
                    "Step 1. Define and set eyebrows with the defining wax pencil using short and light strokes. Step 2. Fill in with the powder along the natural brow contour.",
                },
                {
                  title: "Benefits",
                  description:
                    "Grab the express to easy, full brows. Meet Maybelline New York's Express Brow 2-In-1 Pencil + Powder. It's our fastest all-in-one tool for instantly fuller, natural- looking eyebrows. This eyebrow duo has a precise teardrop tip on one side that easily defines and shapes brows and a powder packed sponge on the other side that fills and smoothes, delivering brows that wow. Get your ticket to an effortless filled brow. Ophthalmologist tested. Suitable for contact wearers.",
                },
                {
                  title: "Ingredients",
                  description:
                    "G2016451 - INGREDIENTS: SILICA, ISODECYL NEOPENTANOATE, SYNTHETIC WAX, OCTYLDODECANOL, DICALCIUM PHOSPHATE, DIMETHICONE, CAPRYLIC/CAPRIC TRIGLYCERIDE, HYDROGENATED STYRENE/METHYLSTYRENE/INDENE COPOLYMER, POLYHYDROXYSTEARIC ACID, ETHYLHEXYL PALMITATE, SORBITAN ISOSTEARATE, ISOSTEARIC ACID, TOCOPHEROL, LECITHIN, POLYGLYCERYL-3 POLYRICINOLEATE, STEARALKONIUM HECTORITE, ALUMINA, PROPYLENE CARBONATE, GLYCERIN, PENTAERYTHRITYL TETRA-DI-T-BUTYL HYDROXYHYDROCINNAMATE [+/- MAY CONTAIN: CI 77491, CI 77492, CI 77499 / IRON OXIDES, CI 77891 / TITANIUM DIOXIDE, MICA]. FIL D265520/1G2016450 - INGREDIENTS: SYNTHETIC WAX, DIISOSTEARYL MALATE, POLYETHYLENE, DIMER DILINOLEYL DIMER DILINOLEATE, OCTYLDODECANOL, CETEARYL ETHYLHEXANOATE, KAOLIN, BORON NITRIDE, COPERNICIA CERIFERA CERA / CARNAUBA WAX / CIRE DE CARNAUBA, DISTEARDIMONIUM HECTORITE, ALUMINUM HYDROXIDE, PROPYLENE CARBONATE, CAPRYLIC/CAPRIC TRIGLYCERIDE, POLYHYDROXYSTEARIC ACID, PENTAERYTHRITYL TETRA-DI-T-BUTYL HYDROXYHYDROCINNAMATE, ISOSTEARIC ACID, LECITHIN, POLYGLYCERYL-3 POLYRICINOLEATE, DICALCIUM PHOSPHATE, ALUMINA, TOCOPHEROL, GLYCERIN [+/- MAY CONTAIN: MICA, CI 77491, CI 77492, CI 77499 / IRON OXIDES, CI 77891 / TITANIUM DIOXIDE, CI 77510 / FERRIC AMMONIUM FERROCYANIDE, CI 42090 / BLUE 1 LAKE]. FIL D265516/1",
                },
              ],
              price: 9.99,
              product_variants: [
                {
                  id: 7,
                  name: "color",
                  product_variant_options: [
                    {
                      id: 11,
                      name: "Light Blonde",
                      hex_color: "c5a171",
                    },
                    {
                      id: 12,
                      name: "Black Brown",
                      hex_color: "50423e",
                    },
                  ],
                },
              ],
              skus: [
                {
                  id: 10,
                  image_url_index: 0,
                  price: 9.99,
                  seller_sku_id: "ABCD-10",
                  sku_product_variant_options: [
                    {
                      product_variant_id: 7,
                      product_variant_option_id: 11,
                    },
                  ],
                },
                {
                  id: 11,
                  image_url_index: 2,
                  price: 9.99,
                  seller_sku_id: "ABCD-11",
                  sku_product_variant_options: [
                    {
                      product_variant_id: 7,
                      product_variant_option_id: 12,
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    ],
  },
};
export default products.data.campaign[0];
