import { FunctionalComponent, h } from "preact";
import { ProductVariantOptionsData } from "../../../types/types";
import { PSmall } from "@tempoplatform/tpds/_dist/elements/typography";
import clsx from "clsx";

interface Props {
  name: string;
  options: Array<ProductVariantOptionsData>;
  selectedIndex: number;
  setSelectedIndex: any;
  setProductVariant: (key: any, value: any) => void;
}

const OptionSelector: FunctionalComponent<Props> = (props: Props) => {
  return (
    <div>
      <PSmall isMedium className="leading-none capitalize !mb-1">
        {props.name}
      </PSmall>
      <div className="flex gap-0.5 flex-wrap">
        {props.options.map(option => (
          <div
            key={option.id}
            onClick={() => {
              props.setSelectedIndex(option.id);
              props.setProductVariant(props.name, option.name);
            }}
            className={clsx(
              `rounded border-2 border-grey-dark-scale-500 w-auto h-[36px] min-w-[36px]`,
              `flex items-center px-2`,
              `leading-none font-medium capitalize`,
              option.id === props.selectedIndex && `text-white bg-black`,
            )}
          >
            {option.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OptionSelector;
