import { FunctionalComponent, h } from "preact";
import CloseIcon from "@mui/icons-material/Close";
import { logEvent } from "../../logging/eventProducer";
import styled from "@emotion/styled";

const Button = styled.button({
  borderRadius: "50%",
  height: "26px",
  width: "26px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderWidth: "0px",
});

interface Props {
  vidRef: any;
  timerCompleted?: any;
}

const CloseButton: FunctionalComponent<Props> = (props: Props) => {
  const { vidRef, timerCompleted } = props;
  return (
    <Button
      style={{
        backgroundColor: timerCompleted ? "#fff" : "transparent",
        border: timerCompleted ? "2px solid #e7e7e78c" : "2px solid transparent",
      }}
      onClick={() => {
        // @ts-ignore
        vidRef && vidRef.current && vidRef.current.pause();
        // @ts-ignore
        if (typeof Android !== "undefined" && Android !== null) {
          // @ts-ignore
          Android.closeAd();
        } else {
          logEvent("TEMPO_CLOSE_AD");
        }
      }}
      disabled={!timerCompleted}
    >
      <CloseIcon fontSize="small" htmlColor={timerCompleted ? "#EB5757" : "transparent"} />
    </Button>
  );
};

export default CloseButton;
