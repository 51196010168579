import { FunctionalComponent, h } from "preact";
import IconButton from "@mui/material/IconButton";
// import InfoBox from "../InfoBox";
import InfoBox2 from "../InfoBox2";
import Button from "@mui/material/Button/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { route } from "preact-router";
import { useState } from "preact/hooks";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

interface Props {
  json: any;
  pid: number;
  data: any;
}

const PreviewProduct: FunctionalComponent<Props> = (props: Props) => {
  const { pid, data } = props;
  const product = data.campaign_products[pid].product;
  const [quantity, setQuantity] = useState(1);
  const [reset, setReset] = useState(false);

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        background: "white",
        display: "flex",
        flexFlow: "column",
      }}
    >
      <div>
        <div style={{ display: "flex", alignItems: "center", height: "11vh" }}>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              marginLeft: "0.75em",
              zIndex: 2,
            }}
          >
            <IconButton
              color="default"
              aria-label="back"
              size={"small"}
              onClick={() => {
                route(`/preview/main`);
              }}
              style={{
                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
                filter: "drop-shadow(0px 0px 0px #000000)",
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div
            style={{
              width: "55vw",
              fontSize: "1.2em",
              fontWeight: "500",
              marginLeft: "0.5em",
              marginRight: "0.5em",
              color: "black",
            }}
          >
            {data.brand_name}
          </div>

          <div
            style={{
              float: "right",
              margin: "auto",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "50%",
                zIndex: 2,
                marginRight: "0.5em",
              }}
            >
              <IconButton
                color="default"
                aria-label="back"
                size={"medium"}
                style={{
                  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
                  width: "34px",
                  height: "34px",
                  filter: "drop-shadow(0px 0px 0px #000000)",
                }}
              >
                <img
                  style={{ aspectRatio: "1", width: "16px", height: "16px" }}
                  alt="edit"
                  src="/assets/share.svg"
                />
              </IconButton>
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "50%",
                zIndex: 2,
              }}
            >
              <IconButton
                color="default"
                aria-label="back"
                size={"small"}
                style={{
                  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
                  width: "34px",
                  height: "34px",
                  filter: "drop-shadow(0px 0px 0px #000000)",
                }}
              >
                <img
                  style={{ aspectRatio: "1", width: "16px", height: "16px" }}
                  alt="edit"
                  src="/assets/save.svg"
                />
              </IconButton>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: "100vw",
            borderTop: "0.1px solid #E2E2E2",
            background: "#868686",
          }}
        />
      </div>
      <div>
        <Splide>
          {product.image_urls.length > 0 ? (
            product.image_urls.map((image, i) => (
              <SplideSlide key={i}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "40vh",
                  }}
                >
                  <img
                    style={{
                      zIndex: 0,
                      objectFit: "contain",
                      maxWidth: "80%",
                      maxHeight: "80%",
                    }}
                    src={image}
                    alt="Image 1"
                  />
                </div>
              </SplideSlide>
            ))
          ) : (
            <SplideSlide>
              <div
                style={{
                  display: "flex",
                  background: "#f7f7f7",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "40vh",
                }}
              >
                <img
                  style={{
                    zIndex: 0,
                    objectFit: "contain",
                    maxWidth: "80%",
                    maxHeight: "80%",
                  }}
                  src={"https://i.imgur.com/f5XByuL.png"}
                  alt="Image 1"
                />
              </div>
            </SplideSlide>
          )}
        </Splide>
        <div
          style={{
            height: "1.65em",
            backgroundColor: "white",
            zIndex: 2,
          }}
        />
      </div>

      <div
        style={{
          width: "100vw",
          backgroundColor: "white",
          zIndex: 2,
        }}
      >
        <div style={{ padding: "0 1em" }}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "black",
                  lineHeight: "20px",
                }}
              >
                {product.name}
              </div>
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "13px",
                  color: "black",
                  lineHeight: "20px",
                }}
              >
                {data.brand_name}
              </div>

              {product.price && (
                <div
                  style={{
                    fontSize: "1em",
                    fontWeight: "500",
                    marginTop: "3px",
                    marginBottom: "0.6em",
                    color: "black",
                  }}
                >
                  ${(product.price * quantity).toFixed(2)}
                </div>
              )}
              {/*<div style={{float: "right"}}>*/}
              {/*    <div style={{*/}
              {/*        border: '1px solid #E6E6E6',*/}
              {/*        boxShadow: 'border-box',*/}
              {/*        borderRadius: '8px'*/}
              {/*    }}>*/}
              {/*        <Button color="secondary" size="small" style={{padding: '0', minWidth: '24px'}}*/}
              {/*                disabled={quantity <= 1}*/}
              {/*                onClick={() => {*/}
              {/*                    setQuantity(quantity - 1);*/}
              {/*                    // @ts-ignore*/}
              {/*                    window.mixpanel.track('Decrease Quantity');*/}
              {/*                }}><span style={{marginBottom: '1px'}}>-</span></Button>*/}
              {/*        <Button color="secondary" disabled*/}
              {/*                style={{*/}
              {/*                    color: "black",*/}
              {/*                    minWidth: '1em',*/}
              {/*                    padding: '6px 0'*/}
              {/*                }}>{quantity}</Button>*/}
              {/*        <Button size="small" color="secondary" style={{padding: '0', minWidth: '24px'}}*/}
              {/*                onClick={() => {*/}
              {/*                    setQuantity(quantity + 1);*/}
              {/*                    // @ts-ignore*/}
              {/*                    window.mixpanel.track('Increase Quantity');*/}
              {/*                }}>*/}
              {/*            <span style={{marginBottom: '2px'}}>+</span>*/}
              {/*        </Button>*/}
              {/*    </div>*/}
              {/*</div>*/}
            </div>
            <div style={{ clear: "both" }}>
              {/*<FormControl size={'small'} style={{margin: '10px 8px 0 0'}} sx={{minWidth: 110}}>*/}
              {/*    <InputLabel id="demo-simple-select-label">Size</InputLabel>*/}
              {/*    <Select*/}
              {/*        labelId="demo-simple-select-label"*/}
              {/*        id="demo-simple-select"*/}
              {/*        value={age}*/}
              {/*        label="Age"*/}
              {/*        onChange={handleChange}*/}
              {/*    >*/}
              {/*        <MenuItem value={10}>X</MenuItem>*/}
              {/*        <MenuItem value={20}>M</MenuItem>*/}
              {/*        <MenuItem value={30}>L</MenuItem>*/}
              {/*        <MenuItem value={30}>XL</MenuItem>*/}
              {/*    </Select>*/}
              {/*</FormControl>*/}
            </div>
          </div>
        </div>
      </div>
      <div style={{ margin: "0em 1em 1em" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div
              style={{
                border: "1px solid",
                borderRadius: "3px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "0.5em 1em",
              }}
            >
              <div style={{ fontWeight: "400" }}>Quantity</div>
              <div style={{ color: "#717171", fontWeight: "500" }}>Small</div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                border: "1px solid",
                borderRadius: "3px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "0.5em 1em",
              }}
            >
              <div style={{ fontWeight: "400" }}>Color</div>
              <div style={{ color: "#717171", fontWeight: "500" }}>Gray</div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          backgroundColor: "white",
          width: "100vw",
        }}
      >
        <div style={{ padding: "0 1em", marginBottom: "0.5em" }}>
          <Button
            style={{
              width: "100%",
              textTransform: "none",
              fontWeight: "500",
              fontSize: "large",
              borderRadius: "10px",
              boxShadow: "0px 6.45015px 16.1254px rgba(0, 0, 0, 0.2)",
            }}
            variant="contained"
            size="large"
            color="secondary"
          >
            <img style={{ height: "21px", padding: "5px" }} src={"/assets/apple.png"} />
          </Button>
        </div>
      </div>
      <div
        style={{
          padding: "0.5em 1em 2em",
        }}
      >
        <div>
          {/*@ts-ignore*/}
          {product.info_boxes.map((box, i) => (
            <div key={i}>
              <InfoBox2 infoBox={box} reset={reset} setReset={setReset} />
              {<Divider />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PreviewProduct;
