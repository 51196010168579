import { FunctionalComponent, h } from "preact";
import { P, PSmall } from "@tempoplatform/tpds/_dist/elements/typography";
import ReviewsSummary from "./ReviewsSummary";
import { Right } from "../../../assets/svgs/ChevronSmall";
import { simplifyLongNumber } from "../../../utils/number";
import ReviewBox from "./ReviewBox";
import dayjs from "dayjs";

interface Props {
  reviews: any;
  reviewScores: any;
  setReviewWrapper: (c: boolean) => void;
}

const ReviewsShort: FunctionalComponent<Props> = props => {
  const { reviews, reviewScores } = props;
  const handleClick = (expanded: boolean) => {
    props.setReviewWrapper(expanded);
  };
  if (!reviewScores || !reviewScores.count) return null;

  const reviewsToShow =
    reviews && reviews.length && reviews.length > 0 ? [...reviews.slice(0, 2)] : null;
  const title = `Ratings & Reviews (${simplifyLongNumber(reviewScores.count)})`;

  return (
    <div className="px-[16px]">
      <div className="h-[44px] flex items-center pb-2">
        <PSmall isMedium>{title}</PSmall>
      </div>
      <ReviewsSummary reviewScores={reviewScores} />
      {reviewsToShow &&
        reviewsToShow.map((review: any, index: number) => {
          return (
            <ReviewBox
              key={index}
              stars={review.star_rating}
              username={review.user_name}
              date={dayjs(review.created_timestamp).format("YYYY/MM/DD")}
              description={review.content}
            />
          );
        })}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            width: "auto",
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
          onClick={() => handleClick(true)}
        >
          <P isMedium>More reviews</P>
          <Right />
        </div>
      </div>

      <br />
    </div>
  );
};

export default ReviewsShort;
