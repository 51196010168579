import { FunctionalComponent, h } from "preact";
import Spinner from "@tempoplatform/tpds/_dist/components/spinner";

const LoadingScreen: FunctionalComponent = () => {
  return (
    <div
      className="w-full h-screen flex flex-col items-center justify-center"
      style={{ height: "100%" }}
    >
      <Spinner size="large" />
    </div>
  );
};

export default LoadingScreen;
