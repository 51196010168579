import { FunctionalComponent, h } from "preact";
import { PSmall } from "@tempoplatform/tpds/_dist/elements/typography";

const NoCampaigns: FunctionalComponent = () => {
  return (
    <div
      className="w-full h-screen flex flex-col items-center justify-center"
      style={{ height: "100%" }}
    >
      <PSmall style={{ marginTop: "10px" }}>No campaigns received</PSmall>
    </div>
  );
};

export default NoCampaigns;
