import { FunctionalComponent, h } from "preact";
import CustomSelect from "./CustomSelect";

interface Props {
  maxQuantity: number;
  selectedQuantity: number;
  handleSelectChange: any;
}

const QuantitySelect: FunctionalComponent<Props> = (props: Props) => {
  const { maxQuantity, selectedQuantity, handleSelectChange } = props;

  const createArrayFromNumber = (number: number) => {
    return Array.from(Array(number + 1).keys());
  };
  const optionsArray = createArrayFromNumber(maxQuantity);
  optionsArray.shift();

  return (
    <div style={{ width: "auto" }}>
      <div className="w-[76px]">
        <CustomSelect
          optionsArray={optionsArray}
          selectedOption={selectedQuantity}
          handleChange={handleSelectChange}
        />
      </div>
    </div>
  );
};

export default QuantitySelect;
