import { FunctionalComponent, h } from "preact";
import { ProductVariantOptionsData } from "../../types/types";
import { PSmall } from "@tempoplatform/tpds/_dist/elements/typography";

interface Props {
  name: string;
  options: Array<ProductVariantOptionsData>;
  selectedIndex: number;
  addBullet: boolean;
}

const OrderSummary: FunctionalComponent<Props> = (props: Props) => {
  const { options, selectedIndex, addBullet } = props;

  const selectedItem = options.find(option => option.id === selectedIndex);

  // return null if no selected item
  if (!selectedItem) {
    return null;
  }

  return (
    <PSmall className="leading-none capitalize text-tertiary">
      {selectedItem.name}
      {addBullet && " • "}
    </PSmall>
  );
};

export default OrderSummary;
