import { FunctionalComponent, h } from "preact";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ActiveComponent } from "../../../website/components/state";

interface Props {
  resetVariantSelection: () => void;
  resetInfoBoxes: () => void;
  setActiveComponent: (component: any) => void;
}

const BackButton: FunctionalComponent<Props> = (props: Props) => {
  const { resetInfoBoxes, setActiveComponent, resetVariantSelection } = props;
  return (
    <ArrowBackIcon
      onClick={() => {
        resetInfoBoxes();
        setActiveComponent(ActiveComponent.Home);
        // reset slider image position after transition is complete
        setTimeout(() => {
          resetVariantSelection();
        }, 200);
      }}
    />
  );
};

export default BackButton;
