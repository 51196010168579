import { h } from "preact";

export function SoundOn(): h.JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
      <path fill="black" d="M0 0H35V35H0z" opacity="0.01" />
      <path
        fill="black"
        d="M8.4 13.431v8.8l8.32 8.24c.48.48 1.28 0 1.28-.8V5.992c0-.8-.8-1.28-1.28-.8l-8.32 8.24zm0 8.8H3.6a1.6 1.6 0 01-1.6-1.6v-5.6a1.6 1.6 0 011.6-1.6h4.8m19.84 16.24a1.6 1.6 0 01-1.12-2.72 12.8 12.8 0 000-18.24 1.6 1.6 0 012.08-2.08 16 16 0 010 22.72 1.6 1.6 0 01-1.12.48l.16-.16z"
      />
      <path
        fill="black"
        d="M23.6 25.031a1.6 1.6 0 01-1.12-2.72 6.4 6.4 0 000-8.96 1.6 1.6 0 012.24-2.24 9.6 9.6 0 010 13.44 1.6 1.6 0 01-1.12.48z"
      />
    </svg>
  );
}

export function SoundOff(): h.JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
      <path fill="black" d="M0 0H35V35H0z" opacity="0.01" />
      <path
        fill="black"
        d="M34 31.76l-3.68-3.68-2.24-2.24-2.24-2.24-2.4-2.4L18 15.76l-6.4-6.4L4.24 2 2 4.24 10 12l-1.6 1.6H3.6c-.96 0-1.6.64-1.6 1.6v5.6c0 .96.64 1.6 1.6 1.6h4.8l8.32 8.24c.48.48 1.28 0 1.28-.8v-9.6l8.96 8.96 4.64 4.64 2.4-2.08zM18 6.16c0-.8-.8-1.28-1.28-.8l-2.56 1.92 3.84 4V6.16zm9.12 2.72c3.84 3.84 4.64 9.44 2.72 14.08l2.4 2.4c3.2-6.08 2.24-13.6-2.88-18.72-.64-.48-1.44-.48-2.08 0-.64.64-.8 1.6-.16 2.24zm-4.64 4.64c1.12 1.12 1.76 2.56 1.76 4.16l2.88 2.88c.8-3.2 0-6.72-2.4-9.28-.64-.48-1.44-.48-2.08 0-.64.48-.64 1.6-.16 2.24z"
      />
    </svg>
  );
}
