import { CREATE_PAYMENT_INTENT, GET_ACTIVE_CAMPAIGNS, REST_EMAIL_API_URL } from "./apiUrls";

export const toGetActiveCampaigns = () => {
  const Httpreq = new XMLHttpRequest();
  Httpreq.open("GET", GET_ACTIVE_CAMPAIGNS, false);
  Httpreq.send(null);
  return JSON.parse(Httpreq.responseText);
};

export const toCreatePaymentIntent = async (requestBody: any) => {
  const endPoint = "/create-payment-intent";
  const response = await fetch(CREATE_PAYMENT_INTENT + endPoint, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestBody),
  });
  return response;
};

export const toUpdatePaymentIntent = async (requestBody: any, paymentIntentId: any) => {
  const endPoint = `/update-payment-intent/${paymentIntentId}`;
  const response = await fetch(CREATE_PAYMENT_INTENT + endPoint, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestBody),
  });
  return response;
};

export const toUploadFormAdTypeData = async (requestBody: any, campaignId: number | string) => {
  const endPoint = `/formData?campaign_id=${campaignId}`;
  const response = await fetch(REST_EMAIL_API_URL + endPoint, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestBody),
  });
  return response;
};
