export const states = [
  { value: "AL", state: "Alabama" },
  { value: "AK", state: "Alaska" },
  { value: "AS", state: "American Samoa" },
  { value: "AZ", state: "Arizona" },
  { value: "AR", state: "Arkansas" },
  { value: "AA", state: "Armed Forces (AA)" },
  { value: "AE", state: "Armed Forces (AE)" },
  { value: "AP", state: "Armed Forces (AP)" },
  { value: "CA", state: "California" },
  { value: "CO", state: "Colorado" },
  { value: "CT", state: "Connecticut" },
  { value: "DE", state: "Delaware" },
  { value: "DC", state: "District of Columbia" },
  { value: "FL", state: "Florida" },
  { value: "GA", state: "Georgia" },
  { value: "GU", state: "Guam" },
  { value: "HI", state: "Hawaii" },
  { value: "ID", state: "Idaho" },
  { value: "IL", state: "Illinois" },
  { value: "IN", state: "Indiana" },
  { value: "IA", state: "Iowa" },
  { value: "KS", state: "Kansas" },
  { value: "KY", state: "Kentucky" },
  { value: "LA", state: "Louisiana" },
  { value: "ME", state: "Maine" },
  { value: "MH", state: "Marshall Islands" },
  { value: "MD", state: "Maryland" },
  { value: "MA", state: "Massachusetts" },
  { value: "MI", state: "Michigan" },
  { value: "FM", state: "Micronesia" },
  { value: "MN", state: "Minnesota" },
  { value: "MS", state: "Mississippi" },
  { value: "MO", state: "Missouri" },
  { value: "MT", state: "Montana" },
  { value: "NE", state: "Nebraska" },
  { value: "NV", state: "Nevada" },
  { value: "NH", state: "New Hampshire" },
  { value: "NJ", state: "New Jersey" },
  { value: "NM", state: "New Mexico" },
  { value: "NY", state: "New York" },
  { value: "NC", state: "North Carolina" },
  { value: "ND", state: "North Dakota" },
  { value: "MP", state: "Northern Mariana Islands" },
  { value: "OH", state: "Ohio" },
  { value: "OK", state: "Oklahoma" },
  { value: "OR", state: "Oregon" },
  { value: "PW", state: "Palau" },
  { value: "PA", state: "Pennsylvania" },
  { value: "PR", state: "Puerto Rico" },
  { value: "RI", state: "Rhode Island" },
  { value: "SC", state: "South Carolina" },
  { value: "SD", state: "South Dakota" },
  { value: "TN", state: "Tennessee" },
  { value: "TX", state: "Texas" },
  { value: "UT", state: "Utah" },
  { value: "VT", state: "Vermont" },
  { value: "VI", state: "Virgin Islands" },
  { value: "VA", state: "Virginia" },
  { value: "WA", state: "Washington" },
  { value: "WV", state: "West Virginia" },
  { value: "WI", state: "Wisconsin" },
  { value: "WY", state: "Wyoming" },
];
