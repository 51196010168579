import { FunctionalComponent, h } from "preact";
import { route } from "preact-router";
import { useEffect, useState } from "preact/hooks";
import { toGetActiveCampaigns } from "../../src/api/api";
import { P, H3 } from "@tempoplatform/tpds/_dist/elements/typography";
import { Window } from "@tempoplatform/tpds/_dist/elements/layout";
import { Button } from "@tempoplatform/tpds/_dist/elements/buttons";
import NoCampaigns from "./NoCampaigns";
import LoadingScreen from "../../src/components/LoadingScreen";

const Home: FunctionalComponent = () => {
  const URL_PATH = "/campaignmobile/";
  const INVALID_ID = "INVALID CAMPAIGN ID";
  const [campaignId, setCampaignId] = useState<any>(["-"]);
  const [selectedCampaign, setSelectedCampaign] = useState<any>("-");
  const [noCampaigns, setNoCampaigns] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getResults();
  }, []);

  const goToCampaign = () => {
    route(`campaignmobile/${selectedCampaign}`);
  };

  const getResults = async () => {
    const json_obj = await toGetActiveCampaigns();
    if (!json_obj || !json_obj.ids || !(json_obj.ids.length > 0)) {
      setNoCampaigns(true);
    } else {
      setCampaignId([...campaignId, ...json_obj.ids]);
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingScreen />;
  }
  if (noCampaigns) {
    return <NoCampaigns />;
  }

  if (campaignId.length > 1) {
    return (
      <div className="m-0 p-4 flex flex-col items-center">
        <a href="https://tempoplatform.com" target="_blank" rel="noreferrer">
          <H3 isMedium>Tempo Platform</H3>
        </a>
        <br />
        <Window>
          <P className="mb-1">Select campaign:</P>
          <select
            className="w-full rounded border-2 border-input bg-input py-2 px-4 mb-2.5"
            value={selectedCampaign}
            onChange={(e: any) => setSelectedCampaign(e.target.value)}
          >
            {campaignId.map((c: any, index: number) => {
              return <option key={index}>{c}</option>;
            })}
          </select>
          <Button onClick={goToCampaign} variant="secondary" style={{ width: "100%" }}>
            OK
          </Button>
        </Window>
        {/* <P isRed id="campaign-select-error"></P> */}
      </div>
    );
  }

  return null;
};

export default Home;
