import { FunctionalComponent, h } from "preact";
import Router from "preact-router";
import { useEffect, useState } from "preact/hooks";
import PreviewMain from "./PreviewMain";
import PreviewProduct from "./PreviewProduct";

const data2 = {
  data: {
    campaign: [
      {
        logo_url: "https://i.imgur.com/RDbNM8d.png",
        image_url: undefined,
        video_url: `https://${process.env.REACT_APP_AWS_S3_BUCKET_URL}/videos/maybelline-1.mp4`,
        top_hex_color: "#FFFFFF",
        bottom_hex_color: "#DDDDDD",
        headline: "Cosmetics for all!",
        brand_name: "Maybelline New York",
        // "is_bottom_headline": true,
        campaign_products: [
          {
            product: {
              image_urls: [
                "https://i.imgur.com/DhxlrMS.png",
                "https://i.imgur.com/DhxlrMS.png",
                "https://i.imgur.com/DhxlrMS.png",
              ],
              url: "https://www.maybelline.com/face-makeup/concealer/instant-age-rewind-eraser-dark-circles-concealer-treatment",
              name: "INSTANT AGE REWIND®",
              info_boxes: [
                {
                  title: "Description",
                  description: `Winner of Allure's Best of Beauty Award for 2018 and the Spring Beauty O-Wards, Maybelline's Instant Age Rewind® Eraser Dark Circles Treatment Concealer makes the eye area appear radiant and refreshed in no time.`,
                },
                {
                  title: "How to Use",
                  description: `<p>1. Twist collar of the Micro-Corrector Applicator in the direction of arrows until the concealer is visible on the sponge.</p>
<p>2. Using the applicator, apply concealer directly to the under-eye area, blending in an outward motion.</p>
<p>3. For extreme dark circles, apply the Neutralizer shade under concealer shade.</p>
<p>4. To add a luminous touch, apply the Brightener shade to the inner corner of eyes, cheek and brow bones and, bridge of the nose.</p>`,
                },
                {
                  title: "Benefits",
                  description:
                    "This concealer for dark circles and fine lines instantly creates a radiant, refreshed looking eye area. Infused with goji berry and Haloxyl™. Packaging may vary, what you receive may not be what is reflected on site.",
                },
                {
                  title: "Ingredients",
                  description: `<p>Aqua/Water/Eau<br />Cyclopentasiloxane<br />Dimethicone<br />Isododecane<br />Glycerin<br />Peg-9 Polydimethylsiloxyethyl Dimethicone<br />Butylene Glycol<br />Dimethicone Crosspolymer<br />Nylon-12<br />Disteardimonium Hectorite<br />Cyclohexasiloxane<br />Peg-10 Dimethicone<br />Cetyl Peg/Ppg-10/1 Dimethicone<br />Phenoxyethanol<br />Sodium Chloride</p>`,
                },
              ],
              price: 9.99,
            },
          },
          {
            product: {
              image_urls: [
                "https://i.imgur.com/8vQscaf.png",
                "https://i.imgur.com/8vQscaf.png",
                "https://i.imgur.com/8vQscaf.png",
              ],
              url: "https://www.maybelline.com/face-makeup/foundation-makeup/fit-me-matte-poreless-foundation",
              name: "FIT ME!® Matte Poreless Foundation",
              info_boxes: [
                {
                  title: "Description",
                  description:
                    "Fit Me® Matte + Poreless Foundation face makeup. This lightweight foundation mattifies and refines pores and leaves a natural, seamless finish.",
                },
                {
                  title: "How to Use",
                  description:
                    "Apply foundation onto skin and blend with fingertips or an applicator.",
                },
                {
                  title: "Benefits",
                  description:
                    "Ideal for normal to oily skin, our exclusive matte foundation formula features micro-powders to control shine and blur pores. Pore minimizing foundation. All day wear. Non-comedogenic. Dermatologist tested. Allergy tested.",
                },
                {
                  title: "Ingredients",
                  description: `
<p>INGREDIENTS: AQUA / WATER / EAU</p>
<p>CYCLOHEXASILOXANE</p>
<p>NYLON-12</p>
<p>ISODODECANE</p>
<p>ALCOHOL DENAT</p>
<p>CYCLOPENTASILOXANE</p>
<p>PEG-10 DIMETHICONE</p>
<p>CETYL PEG/PPG-10/1 DIMETHICONE</p>
<p>PEG-20</p>`,
                },
              ],
              price: 8.49,
            },
          },
          {
            product: {
              image_urls: [
                "https://i.imgur.com/J4eUSbW.png",
                "https://i.imgur.com/J4eUSbW.png",
                "https://i.imgur.com/J4eUSbW.png",
              ],
              url: "https://www.maybelline.com/eye-makeup/eyebrow-makeup/express-brow-2-in-1-pencil-and-powder-eyebrow-makeup",
              name: "EXPRESS BROW 2-IN-1 PENCIL AND POWDER EYEBOW MAKEUP",
              info_boxes: [
                {
                  title: "Description",
                  description:
                    "Try Express Brow 2-In-1 Eyebrow Pencil + Powder by Maybelline for full natural-looking eyebrows, teardrop tip to shape brows and a powder sponge to fill and smooth.",
                },
                {
                  title: "How to Use",
                  description: `<p>1. Define and set eyebrows with the defining wax pencil using short and light strokes.<br /><br />2. Fill in with the powder along the natural brow contour.</p>`,
                },
                {
                  title: "Benefits",
                  description:
                    "Grab the express to easy, full brows. Meet Maybelline New York’s Express Brow 2-In-1 Pencil + Powder. It’s our fastest all-in-one tool for instantly fuller, natural- looking eyebrows. This eyebrow duo has a precise teardrop tip on one side that easily defines and shapes brows and a powder packed sponge on the other side that fills and smoothes, delivering brows that wow. Get your ticket to an effortless filled brow. Ophthalmologist tested. Suitable for contact wearers.",
                },
                {
                  title: "Ingredients",
                  description: `<p>SILICA</p>
<p>ISODECYL NEOPENTANOATE</p>
<p>SYNTHETIC WAX</p>
<p>OCTYLDODECANOL</p>
<p>DICALCIUM PHOSPHATE</p>
<p>DIMETHICONE</p>
<p>CAPRYLIC/CAPRIC TRIGLYCERIDE</p>
<p>HYDROGENATED STYRENE/METHYLSTYRENE/INDENE COPOLYMER</p>
<p>POLYHYDROXYSTEARIC ACID</p>
<p>ETHYLHEXYL PALMITATE</p>
<p>ISOSTEARIC ACID</p>`,
                },
              ],
              price: 9.99,
            },
          },
          {
            product: {
              image_urls: [
                "https://i.imgur.com/ekGCW10.png",
                "https://i.imgur.com/ekGCW10.png",
                "https://i.imgur.com/ekGCW10.png",
              ],
              url: "https://www.maybelline.com/lip-makeup/lip-gloss/lifter-gloss-lip-gloss-makeup-with-hyaluronic-acid/brass",
              name: "LIFTER GLOSS, LIP GLOSS MAKEUP WITH HYALURONIC ACID",
              info_boxes: [
                {
                  title: "Description",
                  description:
                    "Maybelline NY’s Lifter Gloss Bronzed delivers sun-kissed glossy lips and a fuller look. No Filler. Now, in five bronzed shades inspired by golden hour, this lip gloss formula with hyaluronic acid leaves behind lasting hydration and fuller-looking lips.",
                },
                {
                  title: "How to Use",
                  description: `<p>1. Apply lip gloss with the XL Wand&trade; applicator in the center of your upper lip and follow the contours of your mouth.</p>
<p>2. Glide the lip gloss across the entire bottom lip.</p>`,
                },
                {
                  title: "Benefits",
                  description:
                    " Its breakthrough formula with Hyaluronic Acid visibly smooths lip surface and enhances lip contour with high shine for hydrated, fuller-looking lips. No Filler. Plus, its XL Wand™ transforms lips in an easy, one swipe application.",
                },
                {
                  title: "Ingredients",
                  description: `<p>Bis-Diglyceryl Polyacyladipate-2</p>
<p>Pentaerythrityl Tetraisostearate</p>
<p>Polybutene</p>
<p>Tridecyl Trimellitate</p>
<p>Diisostearyl Malate</p>
<p>Silica Dimethyl Silylate</p>
<p>Phenoxyethanol</p>
<p>Ethylhexyl Palmitate</p>
<p>Pentaerythrityl Tetra-Di-T-Butyl Hydroxyhydrocinnamate</p>
<p>Ethylhexylglycerin</p>
<p>Calcium Sodium Borosilicate</p>`,
                },
              ],
              price: 9.49,
            },
          },
        ],
      },
    ],
  },
};

// @ts-ignore
const PreviewCampaign: FunctionalComponent = () => {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    window.addEventListener(
      "message",
      event => {
        if (event.data.type === "preview") {
          setData(event.data.data[0]);
        }
      },
      false,
    );
    // @ts-ignore
    // setData(data2.data.campaign[0]);
  }, []);

  return (
    <div>
      {data && (
        <Router>
          {/*@ts-ignore*/}
          <PreviewMain path="/preview/main" data={data} />
          {/*@ts-ignore*/}
          <PreviewProduct path="/preview/product/:pid" data={data} />
        </Router>
      )}
    </div>
  );
};

export default PreviewCampaign;
