import { FunctionalComponent, h } from "preact";
import { usePlatform } from "react-use-platform";

interface Props {
  style?: any;
  onClick?: () => void;
}
const ShareIcon: FunctionalComponent<Props> = props => {
  const { onClick, style } = props;
  const platform = usePlatform();
  const pathToIcon =
    platform === "ios" ? "/assets/svgs/shareiOS.svg" : "/assets/svgs/shareAndroid.svg";
  const iconStyle = {
    width: "24px",
    height: "24px",
    ...style,
  };
  return <img onClick={onClick} style={iconStyle} alt="share" src={pathToIcon} />;
};

export default ShareIcon;
