import { FunctionalComponent, h } from "preact";
import Router from "preact-router";
import theme from "../src/theme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Home from "./components/Home";
import Campaign from "../src/components/campaign/Campaign";
import PreviewCampaign from "../src/components/preview/PreviewCampaign";
import ThankYou from "../src/components/checkout/ThankYou";
import Checkout from "../src/components/checkout/Checkout";
import CampaignMobileWrapper from "../src/components/campaign/CampaignMobileWrapper";
import { Elements } from "@stripe/react-stripe-js";
import Colors from "@tempoplatform/tpds/_dist/system/Colors";
import { loadStripe } from "@stripe/stripe-js";
//import GlobalStyles from "@tempoplatform/tpds/_dist/system/GlobalStyles";

try {
  const stripePromise = loadStripe(
    "pk_test_51JvmHRHml7UVwjdxYfWHPSrI8GFbmFIGWapctWJkrLMqGmh8Ob3OlaW5bpl8WTnoBypyxJAz2eSk5NWdtmcYxWzl00mtXJ1LQq",
  );
  const options = {
    // passing the client secret obtained from the server
    clientSecret: "{{CLIENT_SECRET}}",
  };
} catch (err) {
  console.error(err);
}

const App: FunctionalComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <Colors />
      <Router>
        <PreviewCampaign path="/preview/product/:pid" />
        {/*@ts-ignore*/}
        <Campaign path="/campaign/:cid" />
        {/*@ts-ignore*/}
        <Campaign path="/campaign/:cid/:os?" />
        {/*@ts-ignore*/}
        <Campaign path="/campaign/:cid/product/:pid" />
        {/*@ts-ignore*/}
        <Campaign path="/campaign/:cid/product/:pid/:os?" />
        {/*@ts-ignore*/}
        <Campaign path="/interstitial/:cid" isInterstitial={true} />
        {/*@ts-ignore*/}
        <Campaign path="/interstitial/:cid/:os?" isInterstitial={true} />
        {/*@ts-ignore*/}
        <Campaign path="/interstitial/:cid/product/:pid" isInterstitial={true} />
        {/*@ts-ignore*/}
        <Campaign path="/interstitial/:cid/product/:pid/:os?" isInterstitial={true} />
        {/*@ts-ignore*/}
        <Checkout path="/checkout/name/:name/price/:price/quantity/:quantity" />
        {/*@ts-ignore*/}
        <CampaignMobileWrapper path="/campaignmobile/:cid" />
        {/*@ts-ignore*/}
        <CampaignMobileWrapper path="/campaignmobile/:cid/product/:pid" />
        <ThankYou path="/thank-you" />
        <Home default />
      </Router>
    </ThemeProvider>
  );
};

export default App;
