import { FunctionalComponent, h } from "preact";
import { SoundOn, SoundOff } from "../../assets/svgs/Sound";

interface Props {
  isMuted: any;
  unmute: () => void;
  mute: () => void;
}

const VolumeAdjust: FunctionalComponent<Props> = (props: Props) => {
  const { isMuted, unmute, mute } = props;
  const clickDecider = () => {
    if (isMuted) {
      unmute();
    } else {
      mute();
    }
  };
  return (
    <button
      style={{
        padding: "4px",
        height: "26px",
        width: "26px",
        position: "relative",
        top: "1px",
        borderRadius: "50%",
        border: "2px solid #e7e7e7",
      }}
      className="flex items-center content-center bg-white"
      onClick={() => clickDecider()}
    >
      {isMuted ? <SoundOff /> : <SoundOn />}
    </button>
  );
};

export default VolumeAdjust;
