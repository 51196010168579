import { FunctionalComponent, h } from "preact";
import { P, PSmall, H6 } from "@tempoplatform/tpds/_dist/elements/typography";
import Checkmark from "../../../assets/svgs/Checkmark";
import clsx from "clsx";

// const OptionsGrid = styled.div(() => [
//   ``,
//   css`
//     div {
//       width: auto;
//     }
//   `,
// ]);

// const TextArea = styled.div(() => [
//   `flex flex-col gap-y-2 w-auto`,
//   css`
//     p {
//       ${`leading-none`}
//     }
//   `,
// ]);

interface Props {
  shippingModes: any;
  selectedShippingMode: any;
  setSelectedShippingMode: any;
}

const ShippingModeSelect: FunctionalComponent<Props> = props => {
  const { selectedShippingMode, setSelectedShippingMode, shippingModes } = props;

  return (
    <div className="w-full border-t border-[#F1F1F1] p-[16px]">
      <PSmall isMedium className="leading-none mb-2">
        Shipping
      </PSmall>
      <div className="flex flex-col gap-y-2 w-full">
        {shippingModes && shippingModes.length > 0 ? (
          shippingModes.map((option, index) => {
            const isActive =
              selectedShippingMode && selectedShippingMode.id
                ? selectedShippingMode.id === option.shipping_option.id
                : false;
            const text = `${option.shipping_option.del_est_min_value} to ${option.shipping_option.del_est_max_value} business days`;
            return (
              <div
                key={index}
                className={clsx(
                  `flex w-full items-center justify-between`,
                  `py-2.5 px-4 rounded-lg overflow-hidden`,
                  `border-2 border-grey-light-scale-200`,
                  isActive ? `bg-grey-light-scale-200` : "bg-white",
                )}
                onClick={() => setSelectedShippingMode(option.shipping_option)}
              >
                <div className="flex flex-col gap-y-2 w-auto">
                  <P isMedium className="capitalize">
                    {option.shipping_option.display_name}
                  </P>
                  <div className="flex gap-x-2">
                    {option.shipping_option.amount === 0 ? (
                      <P isMedium className="text-[#00B612]">
                        Free
                      </P>
                    ) : (
                      <P isMedium>${option.shipping_option.amount}</P>
                    )}
                    <P className="text-tertiary">{text}</P>
                  </div>
                </div>
                <div
                  className={clsx(
                    `w-[26px] h-[26px] rounded-[50%] border-2 flex justify-center items-center`,
                    isActive ? `bg-black border-black` : `bg-white border-grey-light-scale-500`,
                  )}
                >
                  {isActive && <Checkmark />}
                </div>
              </div>
            );
          })
        ) : (
          <H6 isMedium isGreen className="!pb-2 block w-full border-b border-[#F1F1F1]">
            FREE
          </H6>
        )}
      </div>
    </div>
  );
};

export default ShippingModeSelect;
