import { FunctionalComponent, h } from "preact";
import { useState } from "preact/hooks";
import IconButton from "@mui/material/IconButton/IconButton";
import { route } from "preact-router";

interface Props {
  data: any;
  json: any;
}

const Main: FunctionalComponent<Props> = (props: Props) => {
  const [selectedProductIndex, setSelectedProductIndex] = useState<number>(-1);
  const { data } = props;

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        background: "white",
      }}
    >
      <div>
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
            width: "100%",
            zIndex: 1,
          }}
        >
          {data.video_url ? (
            <video
              id="video"
              playsInline={true}
              loop={true}
              autoPlay={true}
              muted={false}
              style={{
                zIndex: 1,
                objectFit: "cover",
                width: "100vw",
                height: "80vh",
                display: selectedProductIndex === -1 ? "block" : "none",
              }}
            >
              <source type="video/mp4" src={data.video_url} />
            </video>
          ) : (
            <img
              src={data.image_url ? data.image_url : "https://i.imgur.com/IKRFGH5.png"}
              style={{
                zIndex: 1,
                objectFit: "cover",
                width: "100vw",
                height: "80vh",
                display: selectedProductIndex === -1 ? "block" : "none",
              }}
            />
          )}
          <img
            src={data.campaign_products[0].product.image_urls[0]}
            style={{
              zIndex: 1,
              objectFit: "contain",
              maxWidth: "70%",
              maxHeight: "60%",
              marginTop: "11vh",
              display: selectedProductIndex === 0 ? "block" : "none",
            }}
          />
          <img
            src={data.campaign_products[1].product.image_urls[0]}
            style={{
              zIndex: 1,
              objectFit: "contain",
              maxWidth: "70%",
              maxHeight: "60%",
              marginTop: "11vh",
              display: selectedProductIndex === 1 ? "block" : "none",
            }}
          />
          <img
            src={data.campaign_products[2].product.image_urls[0]}
            style={{
              zIndex: 1,
              objectFit: "contain",
              maxWidth: "70%",
              maxHeight: "60%",
              marginTop: "11vh",
              display: selectedProductIndex === 2 ? "block" : "none",
            }}
          />
          <img
            src={data.campaign_products[3].product.image_urls[0]}
            style={{
              zIndex: 1,
              objectFit: "contain",
              maxWidth: "70%",
              maxHeight: "60%",
              marginTop: "11vh",
              display: selectedProductIndex === 3 ? "block" : "none",
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            marginTop: "absolute",
            background: "rgb(229, 229, 229, 0.8)",
            borderRadius: "30px",
            height: "2em",
            width: "max-content",
            maxWidth: "90vw",
            left: "50%",
            // @ts-ignore
            top: data.is_bottom_headline ? undefined : "10vh",
            // @ts-ignore
            bottom: data.is_bottom_headline ? "37vh" : undefined,
            transform: "translate(-50%, 0)",
            display: "flex",
            alignItems: "center",
            zIndex: 4,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 1em",
            }}
          >
            <img
              src={data.logo_url}
              style={{
                maxWidth: "8em",
                maxHeight: "1.7em",
                float: "left",
                marginRight: "0.5em",
                borderRadius: "2px",
                objectFit: "contain",
              }}
            />
            <div
              style={{
                minWidth: "30vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  maxWidth: "70vw",
                  fontSize: "1em",
                  color: "black",
                }}
              >
                {data.headline}
              </span>
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            width: "100vw",
            bottom: 0,
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            background: "white",
            boxShadow: "none",
            zIndex: 2,
          }}
        >
          <div
            style={{
              margin: "1.5em 1em 0.5em 1em",
              fontWeight: "500",
              color: "black",
            }}
          >
            <span>Tap to discover</span>
            <span style={{ float: "right" }}>Ad</span>
          </div>
          <div
            style={{
              padding: "0.8em 1em 2.5em 1em",
              display: "grid",
              gap: "0.5em",
              gridTemplateColumns: "repeat(4, 1fr)",
              zIndex: 3,
            }}
          >
            {/*@ts-ignore*/}
            {data.campaign_products.map((cp, index) => (
              <IconButton
                key={index}
                style={{ padding: 0 }}
                onClick={() => {
                  // @ts-ignore
                  setSelectedProductIndex(index);
                  route(`/preview/product/${index}`);
                }}
              >
                {cp.product.image_urls[0] ? (
                  <div
                    key={index}
                    style={{
                      backgroundColor: "white",
                      boxShadow: "0px 3.44904px 17.2452px rgba(0, 0, 0, 0.15)",
                      borderRadius: "10px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      aspectRatio: "1",
                    }}
                  >
                    <img
                      src={cp.product.image_urls[0]}
                      style={{
                        objectFit: "contain",
                        maxWidth: "75%",
                        maxHeight: "75%",
                        aspectRatio: "1",
                      }}
                    />
                  </div>
                ) : (
                  <img
                    src={"https://i.imgur.com/f5XByuL.png"}
                    style={{
                      objectFit: "contain",
                      maxWidth: "100%",
                      borderRadius: "10px",
                      maxHeight: "100%",
                      boxShadow: "0px 3.44904px 17.2452px rgba(0, 0, 0, 0.15)",
                      aspectRatio: "1",
                    }}
                  />
                )}
              </IconButton>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
