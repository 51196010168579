import { FunctionalComponent, h } from "preact";
import VolumeAdjust from "../media/VolumeAdjust";
import CloseButton from "../atoms/CloseButton";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { logEvent } from "../../logging/eventProducer";

interface Props {
  data: any;
  vidRef: any;
  timerCompleted: boolean;
  setTimerCompleted: (value: boolean) => void;
  isInterstitial: boolean;
  isMuted: boolean;
  unMuteInstantly: any;
  muteInstantly: any;
}

const TopControls: FunctionalComponent<Props> = (props: Props) => {
  const {
    data,
    vidRef,
    setTimerCompleted,
    timerCompleted,
    isInterstitial,
    isMuted,
    unMuteInstantly,
    muteInstantly,
  } = props;

  const renderTime = ({ remainingTime }: any) => {
    if (remainingTime === 0) {
      logEvent("TIMER_COMPLETED");
      setTimerCompleted(true);
    }
    return <CloseButton vidRef={vidRef} timerCompleted={timerCompleted} />;
  };

  return (
    <div className="flex items-center gap-x-2 px-3" style={styles.controlsBar}>
      {data.video_url && (
        <span style={{ position: "relative", top: "-1px" }}>
          <VolumeAdjust isMuted={isMuted} unmute={unMuteInstantly} mute={muteInstantly} />
        </span>
      )}

      {timerCompleted ? (
        <CloseButton vidRef={vidRef} timerCompleted={timerCompleted} />
      ) : (
        <CountdownCircleTimer
          isPlaying
          duration={isInterstitial ? 0.01 : 30}
          colors={["#EB5757", "#EB5757"]}
          colorsTime={[30, 0]}
          size={26}
          strokeWidth={2}
        >
          {renderTime}
        </CountdownCircleTimer>
      )}
    </div>
  );
};

const styles = {
  controlsBar: {
    background: "transparent",
    position: "fixed",
    width: "auto",
    height: "45px",
    top: 0,
    right: "0",
    zIndex: 2000,
  },
};

export default TopControls;
