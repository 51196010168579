import { FunctionalComponent, h } from "preact";
import ColorSelector from "./subcomponents/ColorSelector";
import OptionSelector from "./subcomponents/OptionSelector";
import { ProductVariantOptionsData } from "../../types/types";

interface Props {
  id: any;
  name: string;
  options: Array<ProductVariantOptionsData>;
  selectedIndex: number;
  setSelectedIndex: any;
  setProductVariant: (key: any, value: any) => void;
  productVariant: any;
  resetVariantSelection: () => void;
}

const ProductSelect: FunctionalComponent<Props> = (props: Props) => {
  const { setProductVariant, productVariant, resetVariantSelection } = props;

  const getVariant = (): any => {
    return (
      props?.options?.length > 0 &&
      props?.options?.find((op: any) => op?.id === props.selectedIndex)
    );
  };

  const getDefaultVariant = (): any => {
    return props?.options?.length > 0 && props?.options[0]?.name;
  };

  return (
    <>
      {props.name.toLowerCase() === "color" ? (
        <ColorSelector
          name={props.name}
          options={props.options}
          selectedIndex={props.selectedIndex}
          setSelectedIndex={props.setSelectedIndex}
          setProductVariant={setProductVariant}
        />
      ) : (
        <OptionSelector
          name={props.name}
          options={props.options}
          selectedIndex={props.selectedIndex}
          setSelectedIndex={props.setSelectedIndex}
          setProductVariant={setProductVariant}
        />
      )}
    </>
  );
};

export default ProductSelect;
