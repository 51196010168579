import { FunctionalComponent, h } from "preact";
import { H5 } from "@tempoplatform/tpds/_dist/elements/typography";
import { logEvent } from "../../logging/eventProducer";
import { Button } from "@tempoplatform/tpds/_dist/elements/buttons";

interface Props {
  data: any;
}

const BrandRecallScreen: FunctionalComponent<Props> = (props: Props) => {
  const { data } = props;
  const brandName = data.brand_name;

  const handleYes = () => {
    logEvent("BRAND_RECALL_YES");
    closeAd();
  };

  const handleNo = () => {
    logEvent("BRAND_RECALL_NO");
    closeAd();
  };

  const closeAd = () => {
    //close
    logEvent("TEMPO_CLOSE_AD");
  };

  return (
    <div
      className="p-8 w-full h-screen flex flex-col items-center justify-center"
      style={{ height: "100%" }}
    >
      <H5 className="text-center" style={{ textWrap: "pretty" }}>
        Did you see an ad from <span style={{ fontWeight: "600" }}>{brandName}</span> in the last 7
        days?
      </H5>
      <br />
      <div className="flex flex-row items-center justify-center gap-x-4">
        <Button
          className="inline-flex min-w-[80px]"
          variant="info"
          isLarge
          onClick={() => handleYes()}
        >
          Yes
        </Button>
        <Button className="inline-flex min-w-[80px]" isLarge onClick={() => handleNo()}>
          No
        </Button>
      </div>
    </div>
  );
};

export default BrandRecallScreen;
