import "../tpds.d.ts";
import "../src/index.css";
import "../src/tailwindbase.css";
import "@splidejs/splide/dist/css/splide.min.css";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/zoom/zoom.min.css";
import "@tempoplatform/tpds/_dist/system/Fonts";
import "@tempoplatform/tpds/_dist/system/tpds.css";
import "@tempoplatform/tpds/_dist/system/basestyles.css";

import App from "./App";

export default App;
