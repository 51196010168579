import React from "react";
import { FunctionalComponent, h } from "preact";
import { PaymentElement } from "@stripe/react-stripe-js";
import GooglePayButton from "@google-pay/button-react";
import { ProductData } from "../../types/types";
import { logEvent } from "../../logging/eventProducer";

interface Props {
  // shouldShowCheckout: any;
  clientSecret: any;
  product: ProductData;
  // setIsBuying: (value: boolean) => void;
  // os: any
}

const PaymentButton: FunctionalComponent<Props> = props => {
  const { clientSecret, product } = props;
  return (
    <GooglePayButton
      style={styles.paymentButton}
      buttonType="order"
      buttonSizeMode="fill"
      environment="TEST"
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        emailRequired: true,
        shippingAddressRequired: true,
        shippingAddressParameters: {
          allowedCountryCodes: ["US"],
        },
        shippingOptionRequired: true,
        merchantInfo: {
          merchantId: "BCR2DN4TYTCIKLY",
          merchantName: "Tempo Platform, Inc",
        },
        allowedPaymentMethods: [
          {
            type: "CARD",
            parameters: {
              allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
              allowedCardNetworks: ["MASTERCARD", "VISA"],
            },
            tokenizationSpecification: {
              type: "PAYMENT_GATEWAY",
              parameters: {
                gateway: "example",
                gatewayMerchantId: "exampleGatewayMerchantId",
              },
            },
          },
        ],
        transactionInfo: {
          totalPriceStatus: "FINAL",
          totalPriceLabel: "Total",
          totalPrice: "100.00",
          currencyCode: "USD",
          countryCode: "US",
        },
      }}
      onClick={event => {
        event.preventDefault();
        // @ts-ignore
        if (typeof Android !== "undefined" && Android !== null) {
          logEvent("GOOGLE_PAY_CLICKED");
          // @ts-ignore
          Android.launchGooglePay(clientSecret);
        } else {
          alert("Not viewing in webview");
        }
      }}
    />
  );
};

const styles = {
  paymentButton: {
    width: "100%",
    height: "47.5px",
    border: "0px",
    zIndex: 1,
  },
};

export default PaymentButton;
