import { FunctionalComponent, h } from "preact";

interface Props {
  image_url: string;
}

const ImageSection: FunctionalComponent<Props> = (props: Props) => {
  const { image_url } = props;
  return (
    <img
      src={image_url || "https://i.imgur.com/IKRFGH5.png"}
      style={{
        ...styles.image,
      }}
    />
  );
};

const styles = {
  image: {
    zIndex: 1,
    objectFit: "cover",
    height: "100%",
  },
};

export default ImageSection;
