import { h } from "preact";

function Checkmark(): h.JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" fill="none" viewBox="0 0 13 10">
      <path
        fill="#fff"
        d="M11.606.106L4.125 7.567 1.394 4.816c-.142-.114-.37-.114-.484 0l-.825.822c-.113.114-.113.34 0 .483l3.812 3.773a.328.328 0 00.484 0l8.534-8.511c.113-.113.113-.34 0-.482L12.09.106c-.114-.141-.342-.141-.484 0z"
      />
    </svg>
  );
}

export default Checkmark;
