import { FunctionalComponent, h } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { toCreatePaymentIntent } from "../../api/api";
import { STRIPE_PUBLISHABLE_KEY } from "../../constants/keys";
import { states } from "../../constants/states";

interface Props {
  name: string;
  price: string;
  quantity: string;
}

const TEMPO_DOMAIN = `https://${process.env.REACT_APP_TEMPO_ADS_URL}`;

const Checkout: FunctionalComponent<Props> = (props: Props) => {
  const { name, price, quantity } = props;
  console.log(name, price, quantity);
  const [fullName, _setFullName] = useState<string>("");
  const [email, _setEmail] = useState<string>("");
  const [line1, _setLine1] = useState<string>("");
  const [line2, _setLine2] = useState<string>("");
  const [city, _setCity] = useState<string>("");
  const [zip, _setZip] = useState<string>("");
  const [state, _setState] = useState<string>("");
  const nameRef = useRef(fullName);
  const emailRef = useRef(email);
  const line1Ref = useRef(line1);
  const line2Ref = useRef(line2);
  const cityRef = useRef(city);
  const zipRef = useRef(zip);
  const stateRef = useRef(state);
  const [isBuying, setIsBuying] = useState(false);

  const setName = (data: string) => {
    nameRef.current = data;
    _setFullName(data);
  };
  const setEmail = (data: string) => {
    emailRef.current = data;
    _setEmail(data);
  };
  const setLine1 = (data: string) => {
    line1Ref.current = data;
    _setLine1(data);
  };
  const setLine2 = (data: string) => {
    line2Ref.current = data;
    _setLine2(data);
  };
  const setCity = (data: string) => {
    cityRef.current = data;
    _setCity(data);
  };
  const setZip = (data: string) => {
    zipRef.current = data;
    _setZip(data);
  };
  const setState = (data: string) => {
    stateRef.current = data;
    _setState(data);
  };

  // @ts-ignore
  const stripe = window.Stripe(STRIPE_PUBLISHABLE_KEY, {
    apiVersion: "2020-08-27",
  });

  const createPaymentIntent = async () => {
    const requestBody = {
      currency: "USD",
      price,
      quantity,
      productName: name,
      campaignId: 1,
      productId: 0,
    };
    try {
      const response = await toCreatePaymentIntent(requestBody);
      const data = await response.json();
      if (data.error) {
        return { error: data.error };
      }
      return data;
    } catch (err: any) {
      return { error: err.message };
    }
  };

  useEffect(() => {
    const response = createPaymentIntent();
    continueWithPaymentIntent(response);
  }, []);

  const continueWithPaymentIntent = (response: any) => {
    const cs = response.paymentIntent.client_secret;
    const options = {
      clientSecret: cs,
      appearance: {},
    };
    // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
    const elements = stripe.elements(options);
    // Create and mount the Payment Element
    // @ts-ignore
    const paymentElement = elements.create("payment");
    paymentElement.mount("#payment-element");
    const form = document.getElementById("payment-form");
    // @ts-ignore
    form.addEventListener("submit", async event => {
      event.preventDefault();
      setIsBuying(true);
      const { error } = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        // @ts-ignore
        elements,
        confirmParams: {
          return_url: `${TEMPO_DOMAIN}/thank-you`,
          shipping: {
            name: `${nameRef.current} | ${emailRef.current}`,
            address: {
              line1: line1Ref.current,
              line2: line2Ref.current,
              city: cityRef.current,
              state: stateRef.current,
              postal_code: zipRef.current,
              country: "US",
            },
          },
        },
      });
      if (error) {
        setIsBuying(false);
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        const messageContainer = document.querySelector("#error-message");
        console.log(error.message);
      } else {
        setIsBuying(false);
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    });
  };

  return (
    <div>
      <div style={styles.headerWrapper}>
        <div style={styles.name}>{name}</div>
        <div style={styles.amount}>${parseFloat(price).toFixed(2)}</div>
      </div>
      <form id="payment-form" style={{ padding: "1em" }}>
        <h2 style={{ fontWeight: "500", fontSize: "16px" }}>Shipping Information</h2>
        <div style={styles.emailLabel}>Email</div>
        <TextField
          id="email"
          fullWidth
          required
          size="small"
          variant="outlined"
          onChange={e => {
            setEmail(e.target.value);
          }}
          style={{ marginBottom: "1em" }}
        />
        <div style={styles.shippingLabel}>Shipping Address</div>
        <div style={{ marginBottom: "1em" }}>
          <TextField
            id="name"
            fullWidth
            label="Full Name"
            required
            value={fullName}
            size="small"
            variant="outlined"
            style={{ marginBottom: "0.5em" }}
            onChange={e => setName(e.target.value)}
          />
          <TextField
            id="line1"
            fullWidth
            required
            label="Address Line 1"
            size="small"
            variant="outlined"
            style={{ marginBottom: "0.5em" }}
            onChange={e => setLine1(e.target.value)}
          />
          <TextField
            id="line2"
            fullWidth
            label="Address Line 2"
            size="small"
            variant="outlined"
            style={{ marginBottom: "0.5em" }}
            onChange={e => setLine2(e.target.value)}
          />
          <TextField
            id="city"
            fullWidth
            required
            label="City"
            size="small"
            variant="outlined"
            style={{ marginBottom: "0.5em" }}
            onChange={e => setCity(e.target.value)}
          />
          <TextField
            id="zip"
            fullWidth
            type="number"
            required
            label="ZIP"
            size="small"
            variant="outlined"
            style={{ marginBottom: "0.5em" }}
            onChange={e => setZip(e.target.value)}
          />
          <FormControl fullWidth>
            {/*@ts-ignore*/}
            <InputLabel id="state-select-label" size="small" htmlFor="uncontrolled-native">
              State
            </InputLabel>
            <Select
              native
              value={state}
              size="small"
              fullWidth
              sx={{ minWidth: 110 }}
              style={{ marginBottom: "0.5em" }}
              labelId="state-select-label"
              id="state-select"
              variant="outlined"
              onChange={e => setState(e.target.value)}
            >
              {states.map((state: any, index: number) => {
                return (
                  <option value={state.value} key={index}>
                    {state.state}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            id="country"
            fullWidth
            disabled
            value="United States"
            size="small"
            variant="outlined"
          />
        </div>
        <h2 style={{ fontWeight: "500", fontSize: "16px" }}>Payment Details</h2>
        <div id="payment-element" />
        <div style={{ marginBottom: "1.7em" }}>
          <button id="submit" style={styles.buttonStyle}>
            {isBuying ? "Paying..." : "Pay"}
          </button>
        </div>
        <div id="error-message" />
      </form>
    </div>
  );
};

const styles = {
  headerWrapper: {
    padding: "1em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "22px",
  },
  buttonStyle: {
    margin: "0.7em 0 0 0",
    width: "100%",
    textTransform: "none",
    fontWeight: "500",
    fontSize: "large",
    borderRadius: "10px",
    boxShadow: "0px 6.45015px 16.1254px rgba(0, 0, 0, 0.2)",
    color: "white",
    backgroundColor: "black",
    padding: "8px 22px",
    lineHeight: 1.75,
    border: "none",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  name: {
    color: "rgba(26,26,26,.6)",
    fontWeight: 500,
    fontSize: "16px",
    textAlign: "center",
  },
  amount: {
    fontSize: "22px",
    fontWeight: "600",
    boxSizing: "border-box",
    marginTop: "4px",
    fontVariantNumeric: "tabular-nums",
    fontFamily: "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif",
  },
  emailLabel: {
    color: "rgba(0, 0, 0, 0.6)",
    paddingBottom: "0.5em",
    fontSize: "13px",
    fontWeight: 500,
  },
  shippingLabel: {
    color: "rgba(0, 0, 0, 0.6)",
    paddingBottom: "1em",
    fontSize: "13px",
    fontWeight: 500,
  },
};

export default Checkout;
